:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

@import "../../common.scss";

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.813em 0;
  -webkit-animation: slide 0.3s;
  animation: slide 0.3s;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  background: rgba(243, 243, 243, 0.5);
  backdrop-filter: blur(20px);
}
.logo_wrapper_mobile {
  display: none;
}
.search-nav {
  display: none;
}
.download-mobile-btn {
  display: none;
}
.scroll-header-nav-link .NavDownArrow {
  max-width: 35px;
  margin-left: 0;
}
.NavDownArrow {
  width: 100%;
  max-width: 11px;
  margin-left: 5px;
}
#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 0;
  padding-top: 1.5em;
  background-color: transparent;
  z-index: 10;
  .blur_effect {
    position: absolute;
    top: 0;
    z-index: -1;
    box-shadow: none;
    // box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    // background: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(20px);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    transition: 0.5s ease-in !important;
    background: rgb(0 0 0 / 50%);
  }
  .logo_wrapper {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 5%;
    width: 100%;
    max-width: 11em;
    display: flex;
    justify-content: center;
    align-items: center;
    .multimetal_logo {
      width: auto;
      max-width: 100%;
    }
  }
  .icon_arrows {
    font-size: 25px;
  }
  .header-search {
    display: none;
  }
  .nav_links_wrapper {
    .nav_line2_wrapper_flex_wrapper {
      width: 100%;
      // max-width: 97.5%;
    }
  }
  .nav_line1_wrapper_flex,
  .nav_line2_wrapper_flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  // .nav_line2_wrapper_flex {
  //   margin-top: 1em;
  // }
  .nav_line1_wrapper_flex {
    .flex_for_ticker {
      display: flex;
      align-items: center;
      .call_icon_ticker {
        margin-right: 0.5em;
        max-width: 1.5em;
        object-fit: contain;
      }
      .store_locator {
        margin-right: 0.5em;
        max-width: 1.5em;
        object-fit: contain;
      }
    }
    .nav_item1 {
      margin-left: 2.625em;
      text-transform: capitalize;
    }
    .nav_link1 {
      font-size: 1.2em;
      font-family: "Poppins";
      color: $color-141414;
      opacity: 0.8;
    }
    .nav_link1.active {
      position: relative;
      background-color: var(--primary-color);
      &::after {
        content: "";
        position: absolute;
        bottom: -0.4em;
        left: 0;
        background-color: var(--primary-color);
        height: 2.2px;
        width: 2em;
      }
    }
  }
  .nav_line2_wrapper_flex {
    
    .down-arrow-nav {
      display: none;
    }
    .nav_item {
      margin-top: 1em;
      padding: 0 1.194em 1em 0.594em;
      cursor: pointer;
      list-style: none;
      .nav_link_wrapper {
        display: flex;
        align-items: center;
      }
    }
    .nav_item.active {
      .nav_link {
        color: red;
      }
    }
    .inside_wrap {
      background: url(../assets/HamburgerBg.png);
      background-size: cover;
      object-fit: contain;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      color: #ffffff;
      padding: 20% 1rem 0;
      overflow-y: auto;
      .product_nav {
        width: 100%;
      }
      .product_nav_item {
        display: flex;
        justify-content: space-between;
        width: auto;
      }
      .back_btn {
        font-size: 0.875em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: 3em;
        margin-top: 3em;
        .left_arrow {
          width: 100%;
          max-width: 0.938em;
          margin-right: 0.55em;
        }
      }
    }
    .inside_wrap.active {
      display: block;
    }
    .headerdownarrow {
      position: absolute;
      width: 100%;
      max-width: 0.65em;
      right: -1.1em;
      top: 0.7em;
      cursor: pointer;
    }
    .nav_link {
      cursor: pointer;
      text-align: left;
      font: normal normal 300 20px/22px Poppins;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      text-decoration: none;
    }
    _button_d {
      margin-left: 1.9583333333333335vw;
      gap: 0.78125vw;
      display: flex;
    }
    .header_button_d {
      display: flex;
    }
    .header_button_d li {
      list-style: none;
    }
    .down_brohcer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .nav_link:hover {
      color: var(--primary-color);
    }
    .nav_link:active {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -0.4em;
        left: 0;
        background-color: var(--primary-color);
        height: 2.2px;
        width: 2em;
      }
    }

    .dropdown_box {
      overflow: hidden;
      position: absolute;
      width: 17.875em;
      background: rgba(0, 0, 0, 0.83);
      backdrop-filter: blur(50px);
      opacity: 0;
      border-radius: 0.313em;
      top: 2.4em;
      left: 0;
      padding: 30px 15px 0.7em 15px;
      height: 0;
      transition: all 0.5s ease-out;
      transform-origin: top;
      transform: scaleY(0);
    }
    .nav_item:hover {
      .dropdown_box {
        height: auto;
        transform: scaleY(1);

        opacity: 1;
      }
      .dropdown_list_item,
      .dropdown_heading {
        opacity: 1;
      }
    }
    .dropdownboxuparrow {
      position: absolute;
      width: 100%;
      max-width: 1em;
      top: -1em;
      left: 1.5em;
    }
    .dropdown_heading {
      @include textStyles(1.25em, 1.5em, normal);
      font-family: "Poppins";
      color: #fff;
      cursor: pointer;
      padding-left: 1.125em;
      padding-right: 1.125em;
      padding-top: 1em;
      position: relative;
      opacity: 0;
      // text-transform: capitalize;
    }
    .dropdown_heading_plus_minus {
      position: absolute;
      top: 1em;
      right: 0.5em;
    }
    .dropdown_heading.active {
      font-weight: bold;
      color: var(--primary-color) !important;
    }
    .dropdown_heading:hover {
      color: var(--primary-color) !important;
    }
    .wrapper_for_subnav {
      overflow-y: auto;
      transition: transform 0.3s ease-out;
      transform-origin: top;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #333333;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-F5B071;
      }
    }
    .dropdown_list {
      display: block;
    }
    .dropdown_list.active {
      display: block;
    }
    .wrapper_for_subnav.active {
      max-height: 30em;
      transform: scaleY(1);
    }
    .dropdown_list_item {
      border-bottom: 1px solid rgba(35, 31, 32, 0.1);
      padding: 0.563em 1.6em 0.563em 1.7em;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      transition-delay: 0.2s;
    }
    .dropdown_list_item_link {
      @include textStyles(1.125em, 1.563em, normal);
      font: normal normal 300 15px/22px Poppins;
      color: #ffffff;
      text-decoration: none;
      text-transform: capitalize;
      &:hover {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }
    .dropdown_list_item_link.active {
      font-weight: bold;
      color: var(--primary-color);
    }
    .dropdown_list_item:last-child {
      border-bottom: 0;
    }
    .multi-dropdown {
      display: flex;
      width: 55em;
      left: -300%;
    }
    .wrapper_for_subnav_div h5 span {
      font: normal normal normal 18px/22px Poppins;
      padding-bottom: 25px;
      color: #fff;
    }
    .wrapper_for_subnav_div h5 span:hover {
      text-decoration: underline;
      color: #f5b071;
    }
    .download_icon {
      width: 100%;
      max-width: 3em;
    }
  }
}
.contact_us_mob {
  display: none;
}
@media (min-width: 1920px) {
  #header {
    padding: 1.760417vw 0 !important;
  }
}
@media (max-width: 1540px) {
  // #header {
  //   padding-right: 6em;
  // }
  #header .nav_line2_wrapper_flex .nav_link {
    font: normal normal 300 18px/22px Poppins;
  }
}
@media (max-width: 1370px) {
  #header .nav_line2_wrapper_flex .nav_link {
    font: normal normal 300 16px/20px Poppins;
  }
}
@media (max-width: 1280px) {
  #header .logo_wrapper {
    max-width: 6.656em;
  }
  #header .nav_line2_wrapper_flex .nav_link {
    font: normal normal 300 0.85em/22px Poppins;
  }
  #header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: transparent;
  }
  // #header .nav_line2_wrapper_flex .nav_item {
  //   margin-left: 1.588em;
  // }
}
@media (max-width: 1025px) {
  #header {
    padding-right: 1.5em;
  }
  #header .logo_wrapper {
    max-width: 6.656em;
  }
  #header .nav_line2_wrapper_flex .nav_link {
    font-size: 0.75em;
  }
}
@media (max-width: 1023px) {
  .contact_us_mob {
    display: block;
  }
  #header .nav_line2_wrapper_flex .down-arrow-nav img {
    width: 100%;
    max-width: 15.6px;
  }
  .search_nav_li {
    position: absolute;
    top: 15%;
    left: 15px;
    list-style: none;
  }
  .download_desktop {
    display: none !important;
  }
  #header .logo_wrapper_mobile {
    position: absolute;
    top: 7%;
    left: 0;
    height: 0;
    width: 100%;
    max-width: 8.688em;
    border-top-right-radius: 0.313em;
    border-bottom-right-radius: 0.313em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo_wrapper_mobile img {
    width: 125px;
  }
  .download-mobile-btn {
    display: block;
  }
  .download-desktop-btn {
    display: none;
  }
  #header {
    .investor_contact_text {
      color: #fff;
    }

    .nav_links_wrapper {
      .nav_line2_wrapper_flex_wrapper {
        height: 100vh;
      }
    }

    .nav_line1_wrapper_flex {
      display: none;
    }
    .nav_line2_wrapper_flex_wrapper {
      position: fixed;
      top: 0;
      left: -100%;
      // background-color: $color-141414;
      background: url(../assets/HamburgerBg.png);
      background-size: cover;
      object-fit: contain;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      transition: 0.3s left;
      &.active {
        left: 0;
      }
      .search_mobile {
        display: none;
      }
      .header_button_d {
        margin-left: 0vw;
        gap: 0vw;
        display: flex;
        padding: 0;
      }
      .down_brohcer {
        flex-direction: column;
        row-gap: 15px;
        margin-top: 25px;
      }
      .down_brohcer p {
        font: normal normal 300 3.0362694300518134vw Poppins;
      }
      // .download_icon {
      //   margin-left: 0.9895833333333334vw;
      //   margin-bottom: 15px;
      // }
    }
    .nav_social_media_icons {
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      .follow_us_text {
        font-size: 1.6em;
        letter-spacing: 0.16px;
        font-family: "Poppins";
        font-weight: 500;
        text-transform: capitalize;
        text-align: center;
        color: $color-FFFFFF;
        margin-bottom: 1.188em;
      }
      .nav_social_icons {
        width: 100%;
        max-width: 2.34em;
        height: 2.34em;
        margin-right: 2em;
      }
      a:last-child {
        .nav_social_icons {
          margin-right: 0;
        }
      }
    }
    .download_icon img {
      width: 5.917957vw;
      height: 5.917957vw;
    }
    .search-nav {
      display: flex;
      border-bottom: 0;
      margin-bottom: 25px;
      margin-top: 30px;
      width: 95vw;
    }
    .search-nav input {
      width: 100%;
      background-color: transparent;
      border-radius: 25px;
      padding: 15px 25px;
      border: 1px solid #f5b071;
      margin-right: 10px;
    }
    .search-nav button {
      background: transparent linear-gradient(180deg, #f5b071 0%, #673200 100%)
        0% 0% no-repeat padding-box;
      height: 7.229167vw;
      width: 7.229167vw;
      border-radius: 50%;
      border: none;
    }
    .search-nav img {
      width: 3.197917vw;
      height: 3.197917vw;
    }
    .nav_line2_wrapper_flex {
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: nowrap;
      height: 37em;
      overflow-y: auto;
      margin-top: 40%;
      padding-left: 1rem;
      margin-left: 0;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #333333;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5c5656;
      }
      .down-arrow-nav {
        display: block;
      }
      .nav_item {
        margin-left: 0;
        margin-top: 0;
        border-bottom: 0.16474464579901152vw solid #fff;
        padding-bottom: 2.7589236683141132vw;
        width: 98%;
      }
      .nav_link_wrapper {
        position: relative;
        justify-content: space-between;
        // width: fit-content;
      }

      .headerdownarrow {
        font-size: 1.6em;
        color: $color-FFFFFF;
        font-family: "Poppins";
        font-weight: 500;
        top: 50%;
        transform: translateY(-50%);
        right: -1em;
        display: none;
      }

      .nav_link {
        color: #ffffff;
        font-size: 1.6em;
        display: block;
        margin-top: 30px;
        // text-align: center;
      }
      .nav_link.active {
        color: #f5b071;
      }
      .header .nav_link.active {
        font-weight: bold;
        &::after {
          display: none;
        }
      }
      .dropdown_box {
        position: initial;
        width: 22em;
        text-align: left;
        background: transparent;
        height: 0 !important;
        transform: scaleY(0) !important;
        transition-duration: 0.3s;
        display: none;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
      }

      .dropdown_box.active {
        height: auto !important;
        transform: scaleY(1) !important;
        opacity: 1;
        display: block;
        padding: 0.5em 0 1em !important;
        // margin: 0 auto;
        .dropdown_list_item {
          opacity: 1;
        }
      }
      .dropdown_list_item {
        padding: 0;
        border: 0 !important;
        margin-top: 0.5em;
      }
      .dropdown_list_item_link {
        color: $color-FFFFFF;
        opacity: 0.8;
        font: normal normal 300 15px/22px Poppins;
      }
      .dropdown_heading {
        color: $color-FFFFFF;
        font-size: 1.6em;
        padding-top: 0;
        // width: fit-content;/
        // margin: 0 auto;
        margin-top: 1.5em;
        padding-left: 0;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          bottom: initial;
          font: normal normal 300 15px/22px Poppins;
        }
      }

      .wrapper_for_subnav.active {
        height: auto;
        max-height: 100%;
        transform: scaleY(1);
      }
    }
  }
  .hamburger_lines.colored-line .line {
    background-color: var(--text-color);
  }
  .hamburger_lines {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5em;
    width: 3.2em;
    height: 2.4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .line {
      height: 3px;
      border-radius: 1em;
      background-color: var(--bg-white-color);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line1 {
      width: 100%;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line2 {
      width: 50%;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line3 {
      width: 70%;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    &.active {
      .line {
        background-color: $color-FFFFFF;
      }
      .line1 {
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: translateY(1.2em) rotate(135deg);
      }

      .line2 {
        opacity: 0;
        left: -60px;
      }

      .line3 {
        width: 100%;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: translateY(-1em) rotate(-135deg);
      }
    }
  }
}
@media (min-width: 993px) {
  #header {
    .nav_line2_wrapper_flex {
      .nav_item {
        position: relative;
      }
    }
  }
}
@media (max-width: 900px) {
  #header {
    height: 5.5em;
    .logo_wrapper {
      .multimetal_logo {
        width: 100%;
        max-width: 4.931em;
      }
    }
  }
}
@media (max-width: 767px) {
  #header {
    .nav_line2_wrapper_flex {
      .nav_link {
        margin-top: 2.7589236683vw;
        font-size: 1.25em;
        line-height: normal;
      }
      .dropdown_list_item_link {
        font-size: 1em;
      }
    }
  }
}
@media (max-width: 465px) {
  #header .nav_line2_wrapper_flex_wrapper .download_icon button {
    height: 50px;
    width: 50px;
  }
  .search_nav_li {
    position: absolute;
    top: 12%;
    left: 15px;
    list-style: none;
  }
  .logo_wrapper_mobile img {
    width: 4.931em;
  }
  #header .nav_line2_wrapper_flex_wrapper .down_brohcer {
    flex-direction: column;
    // row-gap: 15px;
    margin-top: 25px;
    display: flex;
  }
  #header .nav_line2_wrapper_flex .search-nav button {
    height: 14.229167vw;
    width: 14.229167vw;
  }
  #header .nav_line2_wrapper_flex .search-nav img {
    width: 6.197917vw;
    height: 6.197917vw;
  }
  #header .nav_line2_wrapper_flex .nav_item {
    margin-left: 0;
    border-bottom: 0.1647446458vw solid #fff;
    padding-bottom: 2.7589236683vw;
    width: 95%;
  }
  #header .nav_line2_wrapper_flex .search-nav {
    display: flex;
    border-bottom: 0;
    margin-bottom: 25px;
  }
  #header .nav_line2_wrapper_flex {
    flex-direction: column;
    justify-content: flex-start;
    height: 30em;
    overflow-y: auto;
    width: 100%;
    // margin-top: 40%;
    margin-top: 33%;
    padding-left: 0;
    margin-left: 0;
  }
  #header .search-nav button {
    height: 15.229167vw;
    width: 15.229167vw;
  }
  #header .search-nav img {
    width: 5.197917vw;
    height: 5.197917vw;
  }
  #header .search-nav input {
    width: 85%;
    background-color: transparent;
    border-radius: 25px;
    padding: 10px 20px;
    border: 1px solid #f5b071;
    margin-right: 10px;
  }
  .hamburger_lines {
    right: 1.5em;
    width: 1.5em;
    height: 1.4em;
    &.active {
      .line1 {
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: translateY(0.25em) rotate(135deg);
      }

      .line2 {
        opacity: 0;
        left: -60px;
      }

      .line3 {
        width: 100%;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: translateY(-0.95em) rotate(-135deg);
      }
    }
  }

  // .hamburger_lines {
  //   right: 2.5em;
  //   width: 2.2em;
  //   height: 2.4em;
  // }
  #header .logo_wrapper {
    max-width: 100%;
    justify-content: flex-start;

    .download-mobile-btn {
      display: block;
    }
  }
}

//Animations
@keyframes ticker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.call_icon_ticker {
  text-decoration: blink;
  animation-name: ticker;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  -webkit-animation-name: ticker;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

//Animations
@keyframes opacityAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
