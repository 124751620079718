$fadeUp: 100px;
$fallDown: -200px;
$delay: 50ms;
$duration: 2s;

.presence-s {
  .presence-s_section_c {
    .presence-s-count_sec {
      position: relative;
      .count-up {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        h1 {
          display: inline-flex;
        }
        .text_container {
          span {
            display: inline-block;
          }
          .reg-text {
            transform: translateY($fadeUp);
            opacity: 0;
            display: flex;
            transition: transform $duration/1.5 ease-out, opacity $duration ease;

            &.loaded {
              opacity: 1;
              transform: translateY(0);
            }
          }
          .letter {
            transition: transform $duration cubic-bezier(0.43, 0.1, 0.57, 0.9),
              filter $duration ease, opacity $duration ease;
            opacity: 0;
            filter: blur(5px);

            &:nth-of-type(2) {
              transform: translateY($fallDown + 40px);
              transition-duration: $duration/2;
            }

            &:nth-of-type(3) {
              filter: blur(0);
              transform: translateY($fallDown + 30px);
              transition-duration: $duration/1.3;
            }

            &:nth-of-type(4) {
              transform: translateY($fallDown);
              transition-duration: $duration/1.5;
            }

            &:nth-of-type(5) {
              transform: translateY($fallDown + 50px);
              transition-duration: $duration/2;
            }

            &:nth-of-type(6) {
              filter: blur(0);
              transform: translateY($fallDown + 20px);
              transition-duration: $duration/2.3;
            }

            &.loaded {
              opacity: 1;
              transform: translateY(0);
              filter: blur(0);
            }
          }
        }
      }
    }
    .presence-s_content_section {
      position: relative;
      .text_container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        span {
          display: inline-block;
        }
        .letter {
          transition: transform $duration cubic-bezier(0.43, 0.1, 0.57, 0.9),
            filter $duration ease, opacity $duration ease;
          opacity: 0;
          filter: blur(5px);

          &:nth-of-type(1) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/1.7;
          }

          &:nth-of-type(2) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(3) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(4) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(5) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(6) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(7) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(8) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(9) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(10) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(11) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }

          &:nth-of-type(12) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(13) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(14) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(15) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(16) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(17) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(18) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(19) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(20) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(21) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(22) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(23) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(24) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(25) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(26) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(27) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(28) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(29) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(30) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(31) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }

          &:nth-of-type(32) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(33) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(34) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(35) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(36) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(37) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(38) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(39) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(40) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(41) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }

          &:nth-of-type(42) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(43) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(44) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(45) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(46) {
            filter: blur(0);
            transform: translateY($fallDown + 20px);
            transition-duration: $duration/2.3;
          }
          &:nth-of-type(47) {
            transform: translateY($fallDown + 40px);
            transition-duration: $duration/2;
          }

          &:nth-of-type(48) {
            filter: blur(0);
            transform: translateY($fallDown + 30px);
            transition-duration: $duration/1.3;
          }

          &:nth-of-type(49) {
            transform: translateY($fallDown);
            transition-duration: $duration/1.5;
          }

          &:nth-of-type(50) {
            transform: translateY($fallDown + 50px);
            transition-duration: $duration/2;
          }

          &.loaded {
            opacity: 1;
            transform: translateY(0);
            filter: blur(0);
          }
        }
      }
    }
  }
}

.our_product-s {
  overflow: hidden;
}

@media (max-width: 992px) {
  .presence-s {
    .presence-s_section_c {
      .presence-s-count_sec {
        position: relative;
        .count-up {
          position: relative;
        }
      }
      .presence-s_content_section {
        position: relative;
        .text_container {
          position: relative;
        }
      }
    }
  }
}
