:root {
    --primary-color: #d16c3f;
    --bg-white-color: #ffffff;
    --text-color: #373737;
  }
  
  @import "../../../common.scss";
  
  .awards_sec1 {
    position: relative;
    .common_bg {
      .common_text {
        .heading {
          max-width: 62.361889vw;
        }
      }
    }
  }
  
  .awards_sec2 {
    background: var(--bg-white-color);
    padding: 6.9208vw 0 3.14581vw;
    .awards_slider {
      // min-height: 1160px;
      position: relative;
      .swiper {
        width: 100%;
      }
      .mySwiper {
        .swiper-slide {
          background-position: center;
          background-size: cover;
          width: 100%;
          max-width: 33.719vw;
  
          position: relative;
        }
        .award_img {
          display: block;
          width: 100%;
          max-width: 100%;
          // max-width: 40.464em;
          // max-height: 51.75em;
        }
  
        .awards_content {
          text-align: center;
          display: none;
          padding: 0 3vw;
        }
  
        .awards_title {
          border-bottom: 0.1647446458vw solid var(--primary-color);
          color: var(--primary-color);
          font: normal normal 300 1.458333vw Poppins;
          text-transform: uppercase;
          display: inline-block;
          margin: 1.25vw 0 1.041666vw;
        }
  
        .awards_details {
          color: var(--text-color);
          font: normal normal 300 1.042vw Poppins;
          margin: 0;
          // padding: 0 3.5vw;
        }
  
        .swiper-slide.swiper-slide-visible.swiper-slide-next,
        .swiper-slide.swiper-slide-visible.swiper-slide-prev {
          transform: translate3d(0px, 0px, -483.177px) rotateX(0deg) rotateY(0deg)
            scale(0.914288) !important;
        }
  
        .swiper-slide {
          transform: translate3d(0px, 0px, -587px) rotateX(0deg) rotateY(0deg)
            scale(0.914288) !important;
        }
  
        .swiper-slide.swiper-slide-visible.swiper-slide-active {
          transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg)
            scale(1) !important;
          .awards_content {
            display: block;
            // opacity: 1;
          }
        }
      }
      .swiper-pagination {
        padding-top: 3em !important;
        height: 6vw !important;
      }
  
      .swiper-pagination-bullet {
        // width: 1.573vw;
        // height: 1.573vw;
        width: 0.573vw;
        height: 0.573vw;
        text-align: center;
        // line-height: 20px;
        // font-size: 0.625vw;
        font-size: 0vw;
        color: #fff;
        opacity: 1;
        background: #fff;
        margin: 0 1.380208333333333vw;
        font-size: 0vw;
        color: var(--text-color);
        opacity: 1;
        background: var(--text-color);
        margin: 0 1.380208333333333vw;
  
        position: relative;
        &::before {
          content: "";
          width: 6.573vw;
          height: 6.573vw;
          position: absolute;
          top: 50%;
          right: -1.5vw;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/image/testing/line.svg");
          object-fit: contain;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          display: none;
        }
      }
  
      .swiper-pagination-bullet-active {
        margin-right: 2vw !important;
        color: var(--text-color);
        background: transparent;
        font: normal normal 300 1.042vw Poppins;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-left: 8.75vw !important;
        position: relative;
        &::before {
          display: block;
        }
      }
      .arrow_wrapper {
        position: absolute;
        top: -3em;
        bottom: 0;
        left: 2.77vw;
        right: 2.77vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        .left_arrow,
        .right_arrow {
          width: 100%;
          max-width: 3.4377vw;
          height: 3.4377vw;
          object-fit: contain;
          display: block;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
  
  .awards_sec3 {
    padding: 0 6.3022vw 4.707vw;
    background: var(--bg-white-color);
    .content_container {
      .heading {
        font: normal normal normal 2.865vw Secular One;
        // background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%)
        //   0% 0% no-repeat padding-box;
        background-color: var(--primary-color);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-align: left;
        border-bottom: 0.1647446458vw solid var(--primary-color);
        margin: 0;
        //   max-width: 27vw;
        display: inline-block;
      }
      .text {
        font: normal normal 300 1.042vw Poppins;
        padding: 2.2396vw 0 5.99vw;
        margin: 0;
        color: var(--text-color);
      }
    }
  
    .certificate_container {
      .heading {
        font: normal normal normal 2.865vw Secular One;
        // background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%)
        //   0% 0% no-repeat padding-box;
        background-color: var(--primary-color);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-align: left;
        border-bottom: 0.1647446458vw solid var(--primary-color);
        margin: 0;
        display: inline-block;
      }
      .certificate_row {
        // padding-top: 3.285vw;
        padding-top: 1.014vw;
        margin: 0 auto;
        .certificate_col {
          padding: 0;
          transition: 0.5s ease-in-out;
        }
        .certificate_content {
          width: 100%;
          max-width: 26.823vw;
          margin-top: 2.2713vw;
        }
        .certificate_col:nth-child(3n + 2) {
          .certificate_content {
            margin: 2.2713vw auto 0;
          }
        }
        .certificate_col:nth-child(3n + 3) {
          .certificate_content {
            float: right;
          }
        }
        .certificate_img {
          width: 100%;
          max-width: 100%;
        }
        .certificate_details {
          text-align: left;
          transition: 0.3s ease-in-out;
        }
        .subheading {
          // border-bottom: 0.1647446458vw solid #f2ac6e;
          // border-bottom: 2px solid #f2ac6e;
          color: #fff;
          display: inline-block;
          font: normal normal 300 1.042vw Poppins;
          margin: 1.042vw 0 0.5323vw;
          transition: 0.3s ease-in-out;
          cursor: pointer;
        }
        .subheading.active {
          color: var(--primary-color);
          border-bottom: 2px solid var(--primary-color);
        }
        .description {
          font: normal normal 300 1.042vw Poppins;
          transition: 0.5s ease-in-out;
          margin: 0;
        }
      }
    }
  }
  
  @media (min-width: 1921px) {
    .awards_sec2 {
      min-height: 82.813em;
    }
    .awards_sec3 {
    }
  }
  @media (max-width: 1280px) {
    .awards_sec2 {
      min-height: 65.813em;
    }
  }
  @media (max-width: 840px) {
    .awards_sec2 {
      min-height: 45.813em !important;
    }
  }
  
  @media (max-width: 767px) {
    .awards_sec1 {
      position: relative;
      .common_bg {
        .common_text {
          top: 32vw;
          .heading {
            max-width: 47.5vw;
          }
        }
      }
    }
  
    .awards_sec2 {
      padding: 10.665vw 0 11.97vw;
      .awards_slider {
        .mySwiper {
          .swiper-slide {
            max-width: 52.075vw;
          }
          .awards_content {
            padding: 0;
          }
          .awards_title {
            font: normal normal 300 4.266vw Poppins;
          }
          .awards_details {
            font: normal normal 300 3.74vw Poppins;
          }
          .swiper-slide.swiper-slide-visible.swiper-slide-next,
          .swiper-slide.swiper-slide-visible.swiper-slide-prev {
            transform: translate3d(0px, 0px, -483.177px) rotateX(0deg)
              rotateY(0deg) scale(1) !important;
          }
          .swiper-slide {
            transform: translate3d(0px, 0px, -587px) rotateX(0deg) rotateY(0deg)
              scale(1) !important;
          }
          .swiper-pagination,
          .slick-dots {
            padding-top: 7.996vw !important;
            height: 10.5vw !important;
            // display: none;
          }
          .swiper-pagination-bullet {
            width: 1.75vw;
            height: 1.75vw;
            margin: 0px 2.380208vw;
            &::before {
              width: 15.573vw;
              height: 15.573vw;
              right: -2.5vw;
            }
          }
          .swiper-pagination-bullet-active {
            font: normal normal 300 3.74vw Poppins;
            margin-left: 19vw !important;
            margin-right: 6.4vw !important;
          }
        }
        .arrow_wrapper {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          justify-content: center;
          gap: 9.25vw;
          // margin-top: 7.996vw;
          .left_arrow,
          .right_arrow {
            max-width: 10.666vw;
            height: 10.666vw;
          }
        }
      }
    }
  
    .awards_sec3 {
      padding: 0 4vw 10.661vw;
      .content_container {
        .heading {
          font: normal normal normal 6.399vw Secular One;
        }
        .text {
          font: normal normal 300 3.74vw Poppins;
          padding: 5.864vw 0 11.466vw;
        }
      }
      .certificate_container {
        .heading {
          font: normal normal normal 6.399vw Secular One;
        }
        .certificate_row {
          padding-top: 0;
          .certificate_content {
            max-width: 100%;
            margin-top: 5.3305vw;
          }
          .certificate_col:nth-child(3n + 3) {
            .certificate_content {
              float: initial;
            }
            //  .certificate_col:nth-child(3n+2) {
            //   .certificate_content {
  
            //   }
            // }
          }
          .subheading {
            font: normal normal 300 3.74vw Poppins;
            margin: 3.1983vw 0 2.1322vw;
          }
          .description {
            font: normal normal 300 3.74vw Poppins;
          }
        }
      }
    }
  }
  
  @media (max-width: 540px) {
    .awards_sec2 {
      min-height: 35.813em !important;
    }
    .awards_sec3 .certificate_container .certificate_row .certificate_img {
      max-width: 90%;
      width: 100%;
    }
    .awards_sec3
      .certificate_container
      .certificate_row
      .certificate_col:nth-child(3n + 2)
      .certificate_content {
      margin: 5.3305vw 0 0 0;
    }
  }
  