:root {
    --primary-color: #D16C3F;
    --bg-white-color: #FFFFFF;
    --text-color: #373737;
}

.nav {
    position: fixed;
    z-index: 999;
    /* width: 100%;
    height: 100%; */
}



.nav__content {
    position: fixed;
    padding-top: 32vw;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    transition: all 300ms linear;
    /* top: 50%; */
    /* margin-top: 20px; */
    /* -webkit-transform: translate(0%, -50%); */
    /* transform: translate(0%, -50%); */
    /* width: 100%; */
    text-align: center;
}



#header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    /* padding: 2.7604166666666665vw 0; */
    /* background: transparent; */
}

#header.header-transparent {
    background: transparent;
    position: absolute;
    width: 100%;

}

.serach_icon img {
    width: 1.1979166666666667vw;
    height: 1.1979166666666667vw;
}

.download_icon img {

    width: 1.25vw;
    height: 1.25vw;

}

.download_icon {
    margin-left: 0.9895833333333334vw;
}

.down_brohcer {

    width: 5.520833333333333vw;
    /* height: 2.745744096650192vw; */
}

.down_brohcer p {
    /* height: 2.745744096650192vw; */
    width: 5.520833333333333vw;
    font: normal normal 300 1.0362694300518134vw Poppins;
    line-height: 1.1398963730569949vw;
    margin-bottom: 0;
}

.serach_icon button,
.download_icon button {
    background: var(--primary-color);
    height: 3.2291666666666665vw;
    width: 3.2291666666666665vw;
    border-radius: 50%;
    border: none;
}

#header.header-scrolled {
    background: rgba(55, 142, 181, 0.9);
    padding: 12px;
}

.icon_arrow {
    width: 1.450777202072539vw;
    height: 0.5699481865284974vw;
    font: normal normal normal 1.0362694300518134vw/1.5544041450777202vw Poppins;
}

/* #header .logo{
    margin-right: 9.405820977484899vw;
} */

#header .logo h1 {
    font-size: 24px;
    margin: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    width: 8.839583333333334vw;
    height: 3.8270833333333334vw;
}




.nav__list {
    list-style-type: none;
}




@media (max-width: 768px) {
    #header.header-scrolled {
        padding: 15px 0;
    }
}

a {
    text-decoration: none;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0 !important;
    gap: 8.920833333333333vw;
    position: static;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0.43931905546403077vw 0.8786381109280615vw;
    margin-left: 10px; */
    font: normal normal 300 1.0416666666666667vw/1.1458333333333333vw Poppins;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;

}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: white;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 10px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}


.navbar .hideLinks ul,
.navbar .showLinks ul {
    display: inline-flex;
    align-items: center;
    list-style-type: none;
    gap: 1.7357512953367875vw;
    /* margin-bottom: 0.5rem; */
    padding: 0px;
    margin: 0px;
}

.navbar .hideLinks .header_button_d {
    margin-left: 3.9583333333333335vw;
    gap: 0.78125vw;
}

.navbar .showLinks,
.navbar .hideLinks {
    /* gap: 40px; */
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    color: #2f4d5a;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #67b0d1;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .megamenu {
    position: static;
}

.navbar .megamenu ul {
    margin: 0;
    padding: 10px;
    display: block;
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
}

.navbar .megamenu ul li {
    flex: 1;
}

.navbar .megamenu ul li strong {
    padding: 10px 0 10px 20px;
    display: block;
}

.navbar .megamenu ul li a,
.navbar .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
    padding: 8px 10px;
}

.navbar .megamenu ul li a:hover,
.navbar .megamenu ul li .active,
.navbar .megamenu ul li .active:hover {
    color: #67b0d1;
}

.navbar .megamenu:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}




/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}


.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(43, 111, 142, 0.9);
    transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.iconColor {
    color: white;
    font-size: 30px;
    z-index: 9999;
    position: relative;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #2f4d5a;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #67b0d1;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #67b0d1;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

.navbar-mobile .megamenu ul li a,
.navbar-mobile .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
}

.navbar-mobile .megamenu ul li a:hover,
.navbar-mobile .megamenu ul li .active,
.navbar-mobile .megamenu ul li .active:hover {
    color: #67b0d1;
}



/* lines */
/* #front_page {
    min-height: 100vh;
    background-image: linear-gradient(90deg, transparent, transparent calc(100% / 3), black calc((100% / 3) + 1px), transparent calc((100% / 3) + 1px)), linear-gradient(90deg, transparent, transparent calc((100% / 3 * 2) - 1px), black calc(100% / 3 * 2), transparent calc(100% / 3 * 2));
    transform: translate3d(0, 0, 0);
} */


/* sticky header */

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* padding: 20px 0; */
    -webkit-animation: slide 0.3s;
    animation: slide 0.3s;
    background: #0D0B0B 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 10px #00000029;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);

}

/* 
#header .sticky{
    padding: 1.760417vw 0;
} */




.logo h1.logo-colored,
.sticky .logo h1.logo-white {
    display: none;
}

.logo h1.logo-white,
.sticky .logo h1.logo-colored {
    display: block;
}

.sticky label {
    font-weight: bold;
    text-transform: uppercase;
}

.header-d {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2; */
    margin-left: 6.302083333333333vw;
    margin-right: 6.302083333333333vw;
}

.navbar .menu-icon {
    display: none;
}

#navbar {
    /* justify-content: start; */
}

.mainsec_he {
    display: none !important;
}

@media only screen and (max-width : 767.98px) {
    .navbar a {
        font: normal normal 300 5.198297638660077vw Poppins;
    }

    .header-d {
        margin-left: 4vw;
        margin-right: 4vw;
    }

    #header .logo img {
        width: 16.335529928610654vw;
        height: 10.008786381109281vw;
    }

    .navbar .active {
        font: normal normal 300 5.198297638660077vw Poppins !important;
    }

    .navbar .menu-icon {
        display: block;
    }

    .navbar .hideLinks {
        display: none;
    }

    .navbar .hidedata {
        display: none;
    }

    .navbar .showData {
        display: block;
    }


    #header,
    #header.sticky {
        padding: 4.8vw 0;
    }

    .navbar .showLinks {
        background-color: black;
        display: block;
        /* display: flex;
        flex-direction: column; */
        left: 49%;
        height: 83.93190554640307vw;
        padding: 20px;
        position: absolute;
        text-align: center;
        -webkit-transform: translate(-50%, 50%);
        transform: translate(-50%, 60%);
        width: 100%;
        z-index: 1000;
    }

    .navbar .hideLinks ul,
    .navbar .showLinks ul {
        display: inline-block;
    }


    .header_button_d {
        display: none !important;
    }

    #header .logo {
        margin-right: 43.405820977484899vw;
    }

    .serach_icon button,
    .download_icon button {
        height: 8.1047226798462384vw;
        width: 8.1047226798462384vw;
    }

    .serach_icon img {
        height: 2.9047226798462384vw;
        width: 2.9047226798462384vw;
    }

    .download_icon img {
        width: 2.9179571663920921vw;
        height: 2.9179571663920921vw;
    }

    .mainsec_he {
        display: none;
    }
}