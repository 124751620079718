:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

.indigenious_sec1 {
  height: 100vh;
}
.indigenious_bg {
  height: 100vh;
  background-size: cover;
  position: relative;
  width: 100%;
  background-position: center;
}
.indigenious_bg:before {
  content: "";
  position: absolute;
  background: #141414 0% 0% no-repeat padding-box;
  opacity: 0.75;
  width: 100%;
  height: 100%;
}
.indigenious_scroll {
  left: 10% !important;
}
.indigenious_breadcrumbs {
  position: relative;
  top: 8.916529vw;
  padding-left: 5.1447007138934655vw;
}
.indigenious_heading {
  margin-top: 10vw;
  position: absolute;
}
.indigenious_heading h1 {
  font: normal normal normal 4.305744096650192vw Secular One;
  background: var(--bg-white-color);
  border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-transform: uppercase;
  max-width: 33.161889071938496vw;
  line-height: 4.6803953871499178vw;
  padding-bottom: 0.7589236683141132vw;
  margin-bottom: 0;
}
.indigenious_banner_wrapper {
  position: relative;
  width: 100%;
  max-width: 1646px;
  margin: 0 auto;
  top: 6vw;
  z-index: 1;
}
.indigenious_heading_map {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.metalMap {
  object-fit: contain;
  margin: 0 auto;
}
.metalMap img {
  width: 90%;
  max-width: 100em;
  height: 90vh;
  object-fit: contain;
}
.indigenious-content-section {
  background: var(--bg-white-color);
  padding: 120px 80px;
}
.indigenious-content-section h3 {
  font: normal normal normal 2.865vw Secular One;
  color: var(--primary-color);
  padding-bottom: 20px;
  line-height: 3rem;
}
.indigenious-content-section p {
  font: normal normal 300 1.042vw Poppins;
  width: 60%;
  color: var(--text-color);
}
.indigenious-clients-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2rem;
  padding: 10px 80px;
  align-items: center;
  background: #141414 0% 0% no-repeat padding-box;
  padding-bottom: 50px;
}
.clients-card {
  border: 1px solid #ffc295;
  width: 100%;
  height: 100%;
  max-width: 16em;
  max-height: 15em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clients-card img {
  // width: 120px;
  width: 100%;
  // height: 144px;
}
.clients-card .color {
  display: none;
  // z-index: 99;
}
// .clients-card:hover {
//   background-color: #fff;
// }
.clients-card .color {
  display: inline;
}
.indigenious_row {
  margin-top: 80px;
  row-gap: 3rem;
  column-gap: 3rem;
  justify-content: space-between;
}
@media (max-width: 1375px) {
  .clients-card {
    border: 1px solid #ffc295;
    max-width: 13em;
    max-height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1280px) {
  .indigenious-hero h2 {
    top: 40%;
    left: 5%;
    font-size: 65px;
  }
  .indigenious_row {
    margin-top: 80px;
    row-gap: 3rem;
    column-gap: 2rem;
  }
}
@media (max-width: 1024px) {
  .indigenious_sec1 {
    height: auto;
  }
  .indigenious_bg {
    height: auto;
  }
  .indigenious-hero {
    height: 85vh;
    padding: 0 80px;
    background-position: top 30% right 30%;
  }
  .indigenious_row {
    column-gap: 1rem;
  }
  // .metalMap img {
  //   width: 575px;
  //   height: 600px;
  //   padding-top: 15%;
  // }
  .indigenious-content-section {
    padding: 60px 50px 0 50px;
  }
  .indigenious-hero h2 {
    top: 33%;
    left: 5%;
    font-size: 55px;
  }
  .indigenious-content-section h3 {
    font-size: 45px;
  }
  .indigenious-content-section p {
    font: normal normal 300 3.74vw Poppins;
  }
  .indigenious-clients-section {
    padding: 10px 40px;
  }
}
@media (max-width: 840px) {
  .indigenious_breadcrumbs {
    top: 13.916529vw !important;
  }
  .indigenious_heading {
    margin-left: 5vw;
    margin-top: 12vw;
  }
  .metalMap img {
    width: 550px;
    height: 500px;
  }
  .indigenious-content-section h3 {
    font-size: 35px;
  }
  .indigenious-content-section p {
    width: 90%;
  }
  .clients-card {
    border: 1px solid #ffc295;
    max-width: 13em;
    max-height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clients-card img {
    width: 100%;
  }
  // .clients-card:hover .blackWhite {
  //   display: none;
  // }
  // .clients-card:hover .color {
  //   display: block;
  // }
  // .clients-card {
  //   background-color: #fff;
  // }
  // .clients-card .blackWhite {
  //   display: none;
  // }
  .clients-card .color {
    display: block;
  }
}
@media (max-width: 768px) {
  .metalMap img {
    width: 500px;
    height: 475px;
  }
  .indigenious-content-section {
    padding: 80px 0 0 25px;
  }
  .indigenious_row {
    column-gap: 0rem;
    row-gap: 1em;
  }
}
@media (max-width: 540px) {
  .indigenious_bg {
    height: 28.75rem;
    max-height: 28.75rem;
  }
  .indigenious_heading {
    margin-left: 22vw;
    margin-top: 25vw;
  }
  .indigenious_heading h1 {
    font: normal normal normal 7.305744vw Secular One;
    max-width: 49.161889vw;
    line-height: 7.680395vw;
  }
  .metalMap img {
    width: 315px;
    height: 275px;
    margin-top: 40%;
    margin-left: 5%;
  }
  .indigenious_breadcrumbs {
    top: 25.916529vw !important;
  }
  .indigenious-content-section {
    padding: 40px 20px;
  }
  .indigenious-content-section h3 {
    font-size: 24px;
    line-height: 2rem;
    padding-bottom: 12px;
  }
  .indigenious-content-section p {
    width: 100%;
  }
  .indigenious_row {
    width: 100%;
    column-gap: 0rem;
    margin: 40px 0 0;
    padding: 0;
  }
  // .indigenious_row_padding {
  //   padding: 0 15px;
  // }
  .mobile_padding {
    padding: 0;
    max-width: 30%;
  }
  .clients-card {
    max-width: auto;
    max-height: auto;
  }
  .clients-card img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 467px) {
  .indigenious-clients-section {
    padding: 10px 20px;
  }
  .indigenious-content-section {
    padding: 80px 15px 0;
  }
}

@media (max-width: 320px) {
  .indigenious_breadcrumbs {
    top: 32.916529vw;
  }
  .indigenious-content-section {
    padding: 60px 0px 0 12px;
  }
  .indigenious-content-section h3 {
    font-size: 21px;
  }
  .clients-card {
    max-width: 5em;
    max-height: 4em;
    // background-color: #fff;
  }
  .indigenious_heading h1 {
    margin-top: 15%;
  }
  .metalMap img {
    width: 315px;
    height: 298px;
    margin-top: 50%;
    margin-left: 0;
  }
}
