:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

.acr_spinner_sec1 {
  background: var(--bg-white-color);
  //   padding: 10vw 7.6042vw 0 6.3022vw;
  padding: 14.785vw 7.6042vw 0 6.3022vw;
  color: var(--text-color);
  position: relative;

  .acr_content {
    width: 100%;
    max-width: 32.0834vw;
    margin-left: auto;

    .acr_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .acr_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .acr_image_wrapper {
    margin-top: -4.5vw;

    .acr_img {
      width: 100%;
      max-width: 83.6459vw;
    }
  }
}

.annealing_furnace_sec1 {
  background: var(--bg-white-color);
  //   padding: 10vw 6.3022vw 0;
  padding: 6.45833vw 6.3022vw 0;
  color: var(--text-color);
  position: relative;

  .annealing_content {
    align-self: center;

    .annealing_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .annealing_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }

    .annealing_img {
      width: 100%;
      max-width: 44.1146vw;
      padding-left: 3.2885vw;
    }
  }
}

.furnace_sec1 {
  background: var(--bg-white-color);
  // padding: 10vw 6.3022vw 0;
  padding: 6.45833vw 6.3022vw 0;
  color: var(--text-color);
  position: relative;

  .furnace_content {
    align-self: center;

    .furnace_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .furnace_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }

    .furnace_img {
      width: 100%;
      max-width: 26.73125vw;
      margin-left: 12.6635vw;
    }
  }
}

.billet_heater_sec1 {
  background: var(--bg-white-color);
  // padding: 10vw 6.3022vw 0;
  padding: 14.625vw 6.3022vw 0;
  color: var(--text-color);
  position: relative;

  .billet_content {
    align-self: center;

    .billet_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .billet_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      padding-right: 3.5vw;
      color: var(--text-color);
    }

    .billet_img {
      width: 100%;
      max-width: 50.3125vw;
      padding-left: 0.629166vw;
    }
  }
}

.extrusion_sec1 {
  background: var(--bg-white-color);
  //   padding: 10vw 6.3022vw 0;
  padding: 6.45833vw 0 0 6.3022vw;
  color: var(--text-color);
  position: relative;

  .extrusion_content {
    align-self: center;

    // &:nth-child(2n+1) {
    //   padding-right: 3.5vw;
    // }
    // &:nth-child(2n+2) {
    //   padding-left: 4.2229vw;
    // }
    .extrusion_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .extrusion_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      padding-right: 3.5vw;
      color: var(--text-color);
    }

    .extrusion_img {
      width: 100%;
      max-width: 43.9046vw;
      padding-left: 4.2229vw;
      transform: rotate(270deg);
      float: right;
    }
  }
}

.pilger_sec1 {
  background: var(--bg-white-color);
  // padding: 14.785vw 7.6042vw 0 6.3022vw;
  padding: 6.45833vw 0 0;
  color: var(--text-color);
  position: relative;

  .pilger_container {
    position: relative;
  }

  .pilger_content {
    width: 100%;
    max-width: 34.0834vw;
    margin-left: 6.3022vw;
    position: absolute;
    // top: 4vw;
    top: 6.5vw;
    left: 0;

    .pilger_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .pilger_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      // margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .pilger_image_wrapper {
    .pilger_img {
      width: 100%;
      max-width: 100vw;
    }
  }

  .back_btn_wrapper {
    // position: relative;
    top: 9.25vw;
    left: 6.3022vw;
    // padding-bottom: 0.5vw;
  }
}

.billet_saw_sec1 {
  background: var(--bg-white-color);
  // padding: 14.785vw 7.6042vw 0 6.3022vw;
  padding: 6.45833vw 0 0;
  color: var(--text-color);
  position: relative;

  .billet_saw_container {
    position: relative;
  }

  .billet_saw_content {
    width: 100%;
    max-width: 29.0834vw;
    margin-left: 6.3022vw;
    position: absolute;
    top: 10vw;
    left: 0;

    .billet_saw_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .billet_saw_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .billet_saw_image_wrapper {
    .billet_saw_img {
      width: 100%;
      max-width: 100vw;
    }
  }

  .back_btn_wrapper {
    top: 12vw;
    left: 6.3022vw;
  }
}

.draw_bench_sec1 {
  background: var(--bg-white-color);
  // padding: 14.785vw 7.6042vw 0 6.3022vw;
  padding: 6.45833vw 0 0;
  color: var(--text-color);
  position: relative;

  .draw_bench_container {
    position: relative;
  }

  .draw_bench_content {
    width: 100%;
    max-width: 29.0834vw;
    // margin-left: 6.3022vw;
    position: absolute;
    top: 10.913vw;
    left: 34.21875vw;
    left: 36.153125vw;

    .draw_bench_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .draw_bench_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .draw_bench_image_wrapper {
    .draw_bench_img {
      width: 100%;
      max-width: 100vw;
    }
  }

  .back_btn_wrapper {
    // top: -5vw;
    left: 36.153125vw;
  }
}

.straightener_sec1 {
  background: var(--bg-white-color);
  // padding: 14.785vw 7.6042vw 0 6.3022vw;
  padding: 17.233vw 0 0;
  color: var(--text-color);
  position: relative;

  .straightener_container {
    position: relative;
  }

  .straightener_content {
    width: 100%;
    max-width: 32.0834vw;
    margin-left: 6.3022vw;
    position: absolute;
    top: -3vw;
    left: 0;

    .straightener_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .straightener_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .straightener_image_wrapper {
    .straightener_img {
      width: 100%;
      max-width: 100vw;
      margin-top: 2.402vw;
    }
  }

  .back_btn_wrapper {
    top: 10vw;
    left: 6.3022vw;
  }
}

.back_btn_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: initial;
  top: 13vw;
  z-index: 2;

  &:hover {
    svg {
      margin-left: 0.75em;
      transition: 0.5s ease;
    }
  }

  // .back_img {
  //   width: 100%;
  //   max-width: 3.2298vw;
  //   object-fit: contain;
  // }
  .back_cta_arrow {
    background-color: #d16c3f;
    width: 3.2298vw;
    // min-width: 3.2298vw;
    height: 3.2298vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);

    svg {
      width: 100%;
      max-width: 1.4588vw;
      height: 100%;
      transition: 0.5s ease;
    }
  }

  .back_btn {
    border: none;
    background-color: transparent;
    color: #373737;
    // font-size: 1.25em;
    font-size: 1.041666vw;
    font-weight: 400;
    font-family: "Poppins";
    letter-spacing: 0px;
    line-height: normal;
    margin-left: 0.7em;
  }
}

.hook_sec1 {
  background: var(--bg-white-color);
  padding: 14.785vw 5vw 0 6.3022vw;
  color: var(--text-color);
  position: relative;

  .hook_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hook_content {
    width: 100%;
    max-width: 32.0834vw;
    margin-left: auto;

    .hook_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .hook_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .hook_image_wrapper {
    margin-left: -7.6042vw;
    padding-right: 5vw;

    .hook_img {
      width: 100%;
      max-width: 83.6459vw;
    }
  }
}

.package_sec1 {
  background: var(--bg-white-color);
  padding: 14.785vw 5vw 0 6.3022vw;
  color: var(--text-color);
  position: relative;

  .package_container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .package_content {
    width: 100%;
    max-width: 38.0834vw;
    margin-top: 5em;
    margin-left: auto;

    .package_heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      text-align: left;
      margin: 0;
    }

    .package_details {
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      margin-top: 1.15vw;
      color: var(--text-color);
    }
  }

  .package_image_wrapper {
    margin-top: -7.6042vw;
    margin-right: -7.6042vw;
    padding-left: 5vw;

    .package_img {
      width: 100%;
      max-width: 83.6459vw;
    }
  }
}

@media (max-width: 992px) {
  .annealing_furnace_sec1 {
    padding: 10.5vw 6.3022vw 0;
  }

  // .billet_saw_sec {
  //   .about_breadcrumbs {
  //     top: 15vw;
  //   }
  // }
  .billet_saw_sec1 {
    padding: 10.5vw 0 0;
  }

  .draw_bench_sec1 {
    padding: 10.5vw 0 0;
  }

  .extrusion_sec1 {
    padding: 10.5vw 6.3022vw 0;
  }

  .furnace_sec1 {
    padding: 10.5vw 6.3022vw 0;
  }

  .pilger_sec1 {
    padding: 10.5vw 0 0;
  }

  .straightener_sec1 {
    padding: 19.233vw 0 0;
  }
}

@media (max-width: 767px) {
  .acr_spinner_sec1 {
    padding: 36vw 4vw 0;

    .acr_content {
      max-width: 100%;

      .acr_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .acr_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .acr_image_wrapper {
      margin-top: 11.7271vw;

      .acr_img {
        max-width: 100vw;
      }
    }
  }

  .annealing_furnace_sec1 {
    padding: 36vw 4vw 0;

    .annealing_furnace_container {
      justify-content: center;
      margin: 0 auto;
    }

    .annealing_content {
      padding: 0;

      .annealing_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .annealing_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }

      .annealing_img {
        display: block;
        max-width: 70.1vw;
        padding-left: 0;
        margin: 6.6631vw auto 0;
      }
    }
  }

  .furnace_sec1 {
    padding: 36vw 4vw 0;

    .furnace_container {
      justify-content: center;
      margin: 0 auto;
    }

    .furnace_content {
      padding: 0;

      .furnace_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .furnace_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }

      .furnace_img {
        display: block;
        max-width: 41.348vw;
        padding-left: 0;
        margin: 9.062vw auto 0;
      }
    }
  }

  .billet_heater_sec1 {
    padding: 36vw 4vw 0;

    .billet_heater_container {
      justify-content: center;
      margin: 0 auto;
    }

    .billet_content {
      padding: 0;

      .billet_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .billet_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
        padding-right: 0;
      }

      .billet_img {
        display: block;
        max-width: 65.4384vw;
        padding-left: 0;
        margin: 19.723vw auto 0;
      }
    }
  }

  .extrusion_sec1 {
    padding: 36vw 4vw 0;

    .extrusion_container {
      justify-content: center;
      margin: 0 auto;
    }

    .extrusion_content {
      padding: 0;

      .extrusion_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .extrusion_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
        padding-right: 0;
      }

      .extrusion_img {
        display: block;
        max-width: 70.1vw;
        padding-left: 0;
        margin: 11.7271vw auto 0;
      }
    }
  }

  .pilger_sec1 {
    padding: 36vw 0 0;

    .pilger_content {
      max-width: 100%;
      position: relative;
      top: initial;
      left: initial;
      margin-left: 0;
      padding: 0 4vw;

      .pilger_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .pilger_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .pilger_image_wrapper {
      .pilger_img {
        max-width: 100vw;
        margin: 11.7271vw auto 0;
      }
    }

    .back_btn_wrapper {
      top: initial;
      left: initial;
      padding-left: 4vw;
      // padding-bottom: 7vw;
    }
  }

  .billet_saw_sec1 {
    padding: 36vw 0 0;

    .billet_saw_content {
      max-width: 100%;
      position: relative;
      top: initial;
      left: initial;
      margin-left: 0;
      padding: 0 4vw;

      .billet_saw_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .billet_saw_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .billet_saw_image_wrapper {
      .billet_saw_img {
        max-width: 100vw;
        margin: 11.7271vw auto 0;
      }
    }

    .back_btn_wrapper {
      top: initial;
    }
  }

  .draw_bench_sec1 {
    padding: 36vw 0 0;

    .draw_bench_content {
      max-width: 100%;
      position: relative;
      top: initial;
      left: initial;
      margin-left: 0;
      padding: 0 4vw;

      .draw_bench_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .draw_bench_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .draw_bench_image_wrapper {
      .draw_bench_img {
        max-width: 100vw;
        margin: 11.7271vw auto 0;
      }
    }

    .back_btn_wrapper {
      top: initial;
      left: initial;
      padding-left: 4vw;
    }
  }

  .straightener_sec1 {
    padding: 36vw 0 0;

    .straightener_content {
      max-width: 100%;
      position: relative;
      top: initial;
      left: initial;
      margin-left: 0;
      padding: 0 4vw;

      .straightener_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .straightener_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .straightener_image_wrapper {
      .straightener_img {
        max-width: 100vw;
        margin: 11.7271vw auto 0;
      }
    }

    .back_btn_wrapper {
      top: initial;
      left: initial;
      padding-left: 4vw;
    }
  }

  .back_btn_wrapper {
    position: relative;
    top: initial;
    padding-bottom: 7vw;

    // .back_img {
    //   max-width: 9.864vw;
    // }
    .back_cta_arrow {
      width: 9.864vw;
      height: 9.864vw;

      svg {
        max-width: 4.799vw;
      }
    }

    .back_btn {
      font-size: 4.26438vw;
      margin-left: 0.5em;
    }
  }

  .hook_sec1 {
    padding: 36vw 4vw 0;

    .hook_container {
      flex-direction: column-reverse;

      .hook_content {
        max-width: 100%;
        margin: 0 0 5em;
      }

      .hook_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .hook_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }

    .hook_image_wrapper{
      .hook_img {
        max-width: 100%;
      }
    }
  }
  .package_sec1 {
    padding: 36vw 4vw 0;

    .package_container {
      flex-direction: column;

      .package_content {
        max-width: 100%;
        margin: 0 0 2.5em;
      }

      .package_heading {
        font: normal normal normal 6.399vw Secular One;
      }

      .package_details {
        font: normal normal 300 3.74vw Poppins;
        margin-top: 6.3966vw;
      }
    }
    .package_image_wrapper {
      margin-top: 0;
      .package_img {
        max-width: 100%;
      }
    }
  }
}