:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

.overview_sec1 {
  position: relative;
  .common_bg {
    .common_text {
      .heading {
        max-width: 49.5vw;
      }
    }
  }
  .icon_container {
    position: absolute;
    top: 34vw;
    // top: 55vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon_img {
      display: block;
      width: 100%;
      max-width: 7.9066vw;
      // margin-left: 1.666666vw;
      // margin-right: 1.666666vw;
      &:hover {
      }
    }
    .learn_morn_btn a {
      color: var(--bg-white-color);
    }
  }
}

.overview_sec2 {
  //   padding: 6.146vw 6.3022vw 4.71355vw;
  padding: 10.146vw 0 4.71355vw;
  // padding: 6.146vw 0 3.43125vw;
  background: var(--bg-white-color);

  .heading {
    font: normal normal normal 2.865vw Secular One;
    background: var(--primary-color);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: left;
    border-bottom: 0.1647446458vw solid var(--primary-color);
    margin: 0;
    //   max-width: 27vw;
    display: inline-block;
  }
  .text {
    font: normal normal 300 1.042vw Poppins;
    // margin: 2.24vw 0 4.48vw;
    color: var(--text-color);
  }

  .content_container {
    padding: 0 9vw 0 6.3022vw;
    // padding-right: 4vw;
    // .heading {
    //   max-width: 27vw;
    // }
    .text {
      margin: 2.24vw 0 4.48vw;
    }
  }

  .product_container {
    padding: 0 6.3022vw 0;
    .heading {
      border-bottom: none;
    }
    .product_col {
      padding-bottom: 5.7724vw;
      &:first-child {
        padding-right: 2.344vw;
      }
      &:nth-child(3) {
        padding-left: 2.344vw;
      }
      &:nth-child(5) {
        padding-right: 2.344vw;
      }
    }

    .col_content {
      //   padding-right: 2.344vw;
      .proudct_icon,
      .proudct_icon1,
      .proudct_icon2 {
        width: 100%;
        max-width: 2.9598vw;
        margin-bottom: 0.666vw;
        display: block;
      }
      .fade {
        opacity: 1;
      }
      .proudct_icon1 {
        max-width: 3.315vw;
      }
      .proudct_icon2 {
        max-width: 4.063vw;
      }
      .text {
        margin: 0.646vw 0 2.105vw;
      }
      .banner_gallery_button {
        margin-top: 0;
        button {
          font: normal normal 300 1.042vw Poppins;
          width: 12.0313vw;
          padding: 0.7884678747940692vw 1.757276221856123vw 0.7884678747940692vw
            1.757276221856123vw;
        }
      }
      //   &:nth-child(even) {
      //     padding-right: 2.344vw;
      //   }
      //   &:nth-child(odd) {
      //     padding-left: 2.344vw;
      //   }
    }

    .product_img_wrapper {
      position: relative;
      width: 100%;
      max-width: 43.697vw;
      //   padding-bottom: 5.77vw;
      .product_img {
        width: 100%;
        max-width: 100%;
      }
      .product_small_img {
        position: absolute;
        bottom: -2.5vw;
        left: -4.5vw;
        width: 100%;
        max-width: 16.228vw;
      }
      .product_small_img1 {
        position: absolute;
        bottom: -3.5vw;
        right: -3.75vw;
        width: 100%;
        max-width: 13.699vw;
      }
      .product_small_img2 {
        position: absolute;
        bottom: -0.6vw;
        left: -6.5vw;
        width: 100%;
        max-width: 13.926vw;
      }
    }
    // &:nth-child(even) .col_content {
    //   padding-right: 2.344vw;
    // }
    // &:nth-child(odd) .col_content {
    //   padding-left: 2.344vw;
    // }
  }

  .content_container2 {
    padding: 0 6.3022vw 0;
    .text {
      margin: 2.24635vw 0 4.66145vw;
    }
  }

  .product_slider {
    position: relative;
    .new_swiper {
      .prod_img {
        width: 100%;
        max-width: 100%;
      }
    }
    .mySwiper {
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: fit-content;
        height: fit-content;
        position: relative;
      }
      .prod_img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        // max-height: 35.75em;
      }
      .swiper-pagination {
        padding-top: 3em !important;
        height: 6vw !important;
      }

      .swiper-pagination-bullet {
        // width: 1.573vw;
        // height: 1.573vw;
        width: 0.573vw;
        height: 0.573vw;
        text-align: center;
        // line-height: 20px;
        // font-size: 0.625vw;
        font-size: 0vw;
        color: #fff;
        opacity: 1;
        background: #fff;
        margin: 0 1.380208333333333vw;
        font-size: 0vw;
        color: var(--text-color);
        opacity: 1;
        background: var(--text-color);
        margin: 0 1.380208333333333vw;

        position: relative;
        &::before {
          content: "";
          width: 6.573vw;
          height: 6.573vw;
          position: absolute;
          top: 50%;
          right: -1.5vw;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/image/testing/line.svg");
          object-fit: contain;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          display: none;
        }
      }

      .swiper-pagination-bullet-active {
        color: var(--text-color);
        background: transparent;
        font: normal normal 300 1.042vw Poppins;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-left: 8.75vw !important;
        margin-right: 0 !important;
        position: relative;
        &::before {
          display: block;
        }
      }
    }
    .arrow_wrapper {
      position: absolute;
      top: -3em;
      bottom: 0;
      left: 2.77vw;
      right: 2.77vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 3.4377vw;
        height: 3.4377vw;
        object-fit: contain;
        display: block;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .overview_sec1 {
    .common_bg {
      .common_text {
        .heading {
          max-width: fit-content;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .overview_sec1 {
    position: relative;
    .common_bg {
      .common_text {
        top: 35vw;
      }
    }
    .icon_container {
      top: 57vw;
      .icon_img {
        max-width: 12vw;
        // padding: 1.866vw;
      }
    }
  }

  .overview_sec2 {
    padding: 11.48vw 0 11.48vw;
    .heading {
      font: normal normal normal 6.399vw Secular One;
      max-width: 72vw;
    }
    .text {
      font: normal normal 300 3.74vw Poppins;
    }
    .content_container {
      padding: 0 4vw 0;
      .text {
        margin: 5.9vw 0 0;
      }
    }
    .product_container {
      padding: 0 4vw 0;
      .product_row {
        margin: 0 auto;
      }
      .product_col {
        padding: 0;
        // padding-bottom: 0;
        &:first-child {
          padding: 0;
        }
        &:nth-child(3) {
          padding-left: 0;
        }
        &:nth-child(5) {
          padding-right: 0;
        }
      }
      .col_content {
        .proudct_icon {
          max-width: 9.33vw;
          //   margin: 10.661vw 0 4.266vw;
          margin: 14.26vw 0 4.266vw;
        }
        .proudct_icon1,
        .proudct_icon2 {
          max-width: 13.86vw;
          margin: 14.26vw 0 4.266vw;
        }
        .text {
          margin: 3.82vw 0 6.35vw;
        }
      }
      .product_img_wrapper {
        max-width: 100%;
        .product_small_img,
        .product_small_img1,
        .product_small_img2 {
          // max-width: 42.8677vw;
          max-width: 41vw;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 12vw;
        }
        .product_small_img1 {
          bottom: 13vw;
        }
        .product_small_img2 {
          bottom: 11vw;
        }
        .banner_gallery_button {
          margin-top: 20.505vw;
          button {
            width: 28.52vw;
          }
        }
      }
    }

    .content_container2 {
      padding: 16.525vw 4vw 0;
      .text {
        margin: 6.133vw 0 5.333vw;
      }
    }

    .product_slider {
      .mySwiper {
        .swiper-slide {
          max-width: 66.9vw;
        }
        .product_details {
          font: normal normal 300 3.74vw Poppins;
          margin: 4.266vw 0 7.996vw;
        }
        .swiper-slide.swiper-slide-visible.swiper-slide-next,
        .swiper-slide.swiper-slide-visible.swiper-slide-prev {
          transform: translate3d(0px, 0px, -483.177px) rotateX(0deg)
            rotateY(0deg) scale(0.8555) !important;
        }
        .swiper-pagination,
        .slick-dots {
          height: 4.5vw !important;
        }
        .swiper-pagination-bullet {
          width: 1.75vw;
          height: 1.75vw;
          margin: 0px 2.380208vw;
          &::before {
            width: 15.573vw;
            height: 15.573vw;
            right: -2.5vw;
          }
        }
        .swiper-pagination-bullet-active {
          font: normal normal 300 3.74vw Poppins;
          margin-left: 19vw !important;
        }
      }
      .arrow_wrapper {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        justify-content: center;
        gap: 9.25vw;
        // margin-top: 7.996vw;
        .left_arrow,
        .right_arrow {
          max-width: 10.666vw;
          height: 10.666vw;
        }
      }
    }
  }
}
