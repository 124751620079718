@import "../../../common.scss";

.prodlist_sec1 {
  overflow: hidden;
  .gallery_bg {
    height: 100vh;
    max-height: 100vh;
  }
  .product_heading {
    position: relative;
    top: 18.88358045030203vw;
    padding-left: 6.6447007138934655vw;
    max-width: 90%;
    h1 {
      max-width: fit-content;
      font: normal normal normal 5.305744096650192vw Secular One;
      background: var(--bg-white-color);
      border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-transform: uppercase;
      line-height: 4.6803953871499178vw;
      padding-bottom: 1vw;
      margin-bottom: 0;
      text-align: left;
    }
  }
  .about_wrapper {
    width: 100%;
    max-width: 36.5vw;
    position: absolute;
    right: 15vw;
    bottom: 12.5vh;
    .heading {
      font: normal normal normal 3.438em/6.25rem Secular One;
      letter-spacing: 0px;
      color: $color-FFFFFF;
      text-transform: uppercase;
      opacity: 1;
    }
    .description {
      font: normal normal 300 1.25em/1.875rem Poppins;
      letter-spacing: 0px;
      color: $color-FFFFFF;
    }
  }
}

.prodlist_sec2 {
  padding: 8.75rem 0 10.625rem;
  background: var(--bg-white-color);
  .product_row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .content_box {
      width: 100%;
      max-width: 85%;
      padding: 3.25em 21.625em 2.938em 5.875em;
      margin: 0 0 10rem;
      border: 1px solid var(--primary-color);
      transition: all 0.5s;
      .product_name {
        @include textStyles(3.438em, normal, 0);
        font-family: "Secular One";
        color: var(--primary-color);
        text-transform: capitalize;
        border-bottom: 1px solid var(--primary-color);
        margin-bottom: 0.425em;
      }
      .product_subheading {
        @include textStyles(1.5em, normal, 0);
        font-weight: 500;
        font-family: "Poppins";
        text-transform: capitalize;
        padding: 0;
        margin: 0;
        max-width: 70%;
        color: var(--text-color);
      }
      .product_desc {
        padding: 0.625em 0 2.125em;
        max-width: 70%;
        color: var(--text-color);
      }
      .cta_wrapper {
        margin: 0;
        padding: 0;
        button {
          padding-left: 0;
          transition: all 0.5s;
          &:hover {
            padding-left: 0.8786381109280615vw;
          }
        }
      }
    }
    .content_box:hover {
      background: var(--primary-color);
      .product_name {
        color: var(--bg-white-color);
        border-bottom: 1px solid var(--bg-white-color);
      }
      .product_subheading, .product_desc {
        color: var(--bg-white-color);
      }
      .cta:before {
        background: var(--bg-white-color);
    }
      button {
        color: var(--bg-white-color) !important;
      }
      button:hover {
        color: var(--primary-color) !important;
      }
      svg {
        stroke: var(--primary-color);
      }
    }
    .img_wrapper {
      position: absolute;
      right: 0;
      bottom: 0rem;
      pointer-events: none;
      z-index: 1;
      .product_img {
        width: 100%;
        max-width: 35.875rem;
        // max-height: 18.5em;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .product_row2 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .content_box {
      padding: 3.25em 5.875em 2.938em 21.625em;
    }
    .content_box:hover {
      background: var(--primary-color);
      .product_name {
        color: var(--bg-white-color);
        border-bottom: 1px solid var(--bg-white-color);
      }
      button {
        color: var(--bg-white-color);
      }
    }
    .img_wrapper {
      left: -5vw;
      .product_img {
        max-width: 35.875rem;
      }
    }
  }
  .load_more_cta {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .link {
      @include textStyles(1.5em, normal, 0);
      font-family: "Poppins";
      font-weight: 300;
      width: fit-content;
      color: var(--primary-color);
      text-transform: uppercase;
      border-bottom: 1px solid var(--primary-color);
      // padding-bottom: 0.2em;
    }
  }
}

@media (max-width: 1366px) {
  .prodlist_sec1 {
    .about_wrapper {
      .heading {
        font: normal normal normal 2.438em/4rem Secular One;
      }
      .description {
        font: normal normal 300 1em/1.5rem Poppins;
      }
    }
  }
}

@media (max-width: 1260px) {
  .prodlist_sec1 {
    .about_wrapper {
      .heading {
        font: normal normal normal 2em/3rem Secular One;
      }
      .description {
        font: normal normal 300 0.875em/1.375rem Poppins;
      }
    }
  }

  .prodlist_sec2 {
    .product_row {
      position: relative;
      .content_box {
        padding: 3.25em 15.625em 2.938em 2.875em;
        .product_name {
          @include textStyles(2.438em, normal, 0);
        }
        .product_subheading {
          @include textStyles(1.125em, normal, 0);
          max-width: 70%;
        }
        .product_desc {
          @include textStyles(1em, normal, 0);
          max-width: 80%;
        }
      }
      .img_wrapper {
        .product_img {
          max-width: 25.875rem;
        }
      }
    }
    .product_row2 {
      .content_box {
        padding: 3.25em 2.875em 2.938em 15.625em;
      }
    }
  }
}

@media (max-width: 992px) {
  .prodlist_sec1 {
    .gallery_bg {
      height: 75vh;
      max-height: 75vh;
    }
  }

  .prodlist_sec2 {
    .product_row {
      .content_box {
        padding: 3.25em 7.5em 2.938em 2.875em;
        .product_name {
          @include textStyles(2em, normal, 0);
        }
        .product_subheading {
          @include textStyles(1em, normal, 0);
          max-width: 70%;
        }
        .product_desc {
          @include textStyles(0.813em, normal, 0);
          max-width: 90%;
        }
      }
      .img_wrapper {
        bottom: 5rem;
        max-width: 15.875rem;
        .product_img {
          max-width: 15.875rem;
        }
      }
    }
    .product_row2 {
      .content_box {
        padding: 3.25em 2.875em 2.938em 7.5em;
      }
    }
  }
  .prodlist_sec1 {
    .product_heading {
      top: 50vw;
    }
  }
  .speciality-img-cover {
    width: 100%;
    height: 75vh !important;
    object-fit: cover;
    background: #000000;
  }
}

@media (max-width: 768px) {
  .prodlist_sec1 {
    .product_heading {
      top: 41vw;
      h1 {
        font: normal normal normal 5.305744096650192vw Secular One;
      }
    }
    .mobile_color {
      background-color: #141414;
      z-index: 2;
      position: relative;
    }
  }
  .prodlist_sec2 {
    .product_row {
      .content_box {
        max-width: 100%;
        padding: 3.5em 2.5em;
        .product_name {
          @include textStyles(2em, normal, 0);
        }
        .product_subheading {
          @include textStyles(1em, normal, 0);
          max-width: 80%;
        }
        .product_desc {
          @include textStyles(0.813em, normal, 0);
          max-width: 100%;
        }
      }
      .img_wrapper {
        bottom: 2rem;
        max-width: 15.875rem;
        .product_img {
          max-width: 15.875rem;
        }
      }
    }
    .product_row2 {
      .content_box {
        padding: 3.5em 2.5em;
      }
    }
  }
}

@media (max-width: 600px) {
  .prodlist_sec1 {
    background-color: $color-141414;
    padding-top: 5vh;
    .gallery_bg {
      height: 18.75em;
      max-height: 18.75em;
    }
    .product_heading {
      position: relative;
      // top: initial;
      height: 100%;
      padding-left: initial;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .about_wrapper {
      max-width: 100%;
      position: relative;
      right: initial;
      bottom: initial;
      padding: 4.4em 0 0;
      .description {
        margin-bottom: 0;
      }
    }
  }
  .prodlist_sec2 {
    padding: 3.25rem 0 5.625rem;
    .product_row {
      .content_box {
        max-width: 100%;
        padding: 28px 15px 49px;
        margin: 0 0 6.313rem;
        .product_name {
          @include textStyles(24px, normal, 0);
        }
        .product_subheading {
          @include textStyles(14px, normal, 0);
          max-width: 80%;
        }
        .product_desc {
          @include textStyles(14px, normal, 0);
          max-width: 90%;
        }
      }
      .img_wrapper {
        bottom: 0;
        max-width: 15.813rem;
        .product_img {
          max-width: 15.813rem;
        }
      }
      .cta_wrapper {
        align-items: flex-start;
      }
    }
    .product_row2 {
      .img_wrapper {
        left: auto;
        right: 0;
      }
    }
    .load_more_cta {
      .link {
        font-size: 14px;
      }
    }
  }
}
