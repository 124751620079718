:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

@import "../../../common.scss";

.prodinner_sec1 {
  background-color: $color-141414;
  color: $color-FFFFFF;
  .gallery_bg {
    height: 125vh;
  }
  .product_banner_wrapper {
    position: relative;
    width: 100%;
    max-width: 1646px;
    margin: 0 auto;
    top: 16vw;
    z-index: 1;

    .product_heading {
      position: relative;
      h1 {
        width: fit-content;
        border-bottom: 3px solid var(--bg-white-color);
        font-family: "Secular One";
        color: var(--bg-white-color);
        text-transform: uppercase;
        font-size: 2.865vw;
        background-color: var(--bg-white-color);
      }
    }
    .product_img {
      // max-width: 45.313vw;
      width: 100%;
      max-width: 75%;
    }
    .details_box {
      .product_heading {
        position: relative;
        h1 {
          @include textStyles(1.75em, normal, 0);
          width: fit-content;
          border: 0;
          font-family: "Secular One";
          color: $color-FFC295;
          text-transform: uppercase;
          margin-bottom: 0.2em;
        }
      }
      .table_wrapper {
        overflow-y: auto;
        max-height: 35em;
        margin: 0;
        &::-webkit-scrollbar {
          display: block;
          width: 0.5em;
          height: 8px;
          background-color: #aaa; /* or add it to the track */
        }
        &::-webkit-scrollbar-thumb {
          display: block;
          background: #fff;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          .nobottom_border {
            border-bottom: none;
          }
          .notopbottom_border {
            border-top: none;
            border-bottom: none;
          }
          .notop_border {
            border-top: none;
          }
          &:last-child {
            td {
              border-bottom: 1px solid var(--bg-white-color);
            }
          }
        }
        td {
          @include textStyles(1.042vw, 33px, 0);
          color: var(--bg-white-color);
          border: 1px solid var(--bg-white-color);
          padding: 0.85vw 8px 0.85vw 8px;
          text-align: center;
          font-family: "Poppins";
          text-transform: uppercase;
          font-weight: 300;
        }
        .heading {
          // padding: 0.719vw 8px 0.719vw 2.5em;
          padding: 0.719vw 8px 0.719vw 8px;
          font-weight: 500;
        }
      }
      // .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
      //   max-width: 25em;
      // }
    }
  }
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  max-width: 25em;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.prodinner_sec2 {
  background-color: var(--bg-white-color);
  color: var(--text-color);
  padding: 7.875em 0 5.528em;
  // min-height: 60.75em;
  .text_container {
    .title_wrap {
      .title {
        @include textStyles(2.083vw, 1.2em, 0);
        font-family: "Secular One";
        color: var(--primary-color);
        text-transform: uppercase;
      }
    }
    .description {
      // margin: 2.083vw 0 3.646vw;
      margin: 0 3.646vw;
      @include textStyles(1.25vw, 36px, 0);
      font-family: "Poppins";
      font-weight: 300;
    }
    .accordion_wrap {
      background-color: var(--bg-white-color);
      margin-bottom: 1.813vw;
      box-shadow: none;
      .accordion_summary {
        padding: 0;
        border-bottom: 1px solid var(--text-color);
        .MuiSvgIcon-root {
          fill: var(--text-color);
        }
        p {
          @include textStyles(1.458vw, 1.2em, 0);
          color: var(--primary-color);
          font-family: "Poppins";
          text-transform: uppercase;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0 0 0 3.125em;
        p {
          @include textStyles(1.042vw, 45px, 0);
          font-family: "Poppins";
          color: var(--text-color);
          margin: 2.313em 0 0;
          font-weight: 300;
          ul {
            padding-left: 0;
            list-style: none;
          }
        }
      }
    }
  }

  .download_location {
    // padding-left: 4.792vw;
    // max-width: 95%;
    .share_wrap {
      display: flex;
      align-items: center;
      margin-bottom: 3.5em;
      cursor: pointer;
      .share_icon {
        width: 100%;
        max-width: 3.229vw;
        border-radius: 50%;
        object-fit: contain;
        margin: 1em 1vw 1em 0;
      }
      .share_indication {
        @include textStyles(1.25vw, normal, 0);
        margin: 0;
        font-weight: 300;
        color: var(--text-color);
      }
    }
    .download_box,
    .location_box {
      .title {
        width: fit-content;
        @include textStyles(2.083vw, 1.2em, 0);
        font-family: "Poppins";
        border-bottom: 1px solid var(--text-color);
        padding-bottom: 10px;
        margin: 0 0 2.083vw;
      }
      .description {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 2.083vw 0 2.083vw;
        @include textStyles(1.25vw, 36px, 0);
        font-family: "Poppins";
        font-weight: 300;
        text-decoration: none;
        color: var(--text-color);
        border-bottom: 1px solid var(--text-color);
        padding-bottom: 10px;
      }
      .download_icon {
        width: 100%;
        max-width: 1.25em;
      }
    }
    .location_box {
      // margin-top: 3.906vw;
      margin-top: 0;
      .location_icon {
        width: 100%;
        max-width: 1.125em;
      }
    }
  }
}
.dynamic_specsheet_box {
  height: 100%;
  padding: 0 !important;
  margin: 0 0 1.5em;
  &::-webkit-scrollbar {
    display: block !important;
    height: 1em;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .specsheet_box {
    // max-width: 100%;
    padding: 2.5em 0 1.5em;
    // overflow-x: auto;

    table {
      width: 100%;
      font-size: initial;
      line-height: 1.4em;
      letter-spacing: 0;
      font-family: "Poppins";
      color: var(--text-color);
      border-collapse: collapse;
      text-align: center;
      border: none;
      td {
        min-width: 10em;
        border: 1px solid black;
        padding: 0.5em 1em;
        // * {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   width: 100%;
        //   height: 100%;
        // }
        // div span {
        //   padding: 0.5em 1em;
        // }
      }
      .specsheet_color_1 {
        background: var(--primary-color);
        color: var(--bg-white-color);
      }
      .specsheet_color_2 {
        background: $color-F5B071;
      }
      .specsheet_color_3 {
        background: $color-FFC295;
      }
      .specsheet_column_size_1 {
        min-width: 15em;
      }
      .specsheet_column_size_2 {
        min-width: 20em;
      }
      .specsheet_column_size_3 {
        min-width: 25em;
      }
    }
  }
}
.specsheet {
  width: 100%;
  margin: 2.313em 0 0;
}
.productinner_center_row {
  align-items: center;
}
// .productinner-standard{
//   margin-top: 6em;
// }

@media (max-width: 1600px) {
  .prodinner_sec1 {
    .gallery_bg {
      height: 68vw;
    }
  }
}

@media (max-width: 1200px) {
  .prodinner_sec1 {
    .gallery_bg {
      height: 75vw;
    }
  }
}

@media (max-width: 992px) {
  .details_box {
    .MuiSelect-select {
      height: auto;
      min-height: 2.4375em !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .prodinner_sec1 {
    .gallery_bg {
      height: 100vw;
    }
    .product_banner_wrapper {
      .product_heading {
        h1 {
          font-size: 2.5em;
        }
      }
      .details_box {
        margin-top: 3em;
        .product_heading {
          h1 {
            @include textStyles(1.75em, normal, 0);
          }
        }
        .table_wrapper {
          td {
            font-size: 1em;
          }
        }
      }
    }
  }

  .prodinner_sec2 {
    .text_container {
      .title_wrap {
        .title {
          font-size: 1.75em;
        }
      }
      .description {
        font-size: 1.25em;
      }

      .accordion_wrap {
        .accordion_summary {
          p {
            font-size: 1.5em;
            line-height: 35px;
          }
        }
        .MuiAccordionDetails-root {
          padding: 0 0 0 2em;
          p {
            font-size: 1.125em;
          }
        }
      }
    }
    .download_location {
      .share_wrap {
        .share_icon {
          max-width: 3em;
        }
        .share_indication {
          font-size: 1.25em;
        }
      }
      .download_box,
      .location_box {
        .title {
          font-size: 1.75em;
          line-height: 35px;
        }
        .description {
          font-size: 1em;
          line-height: 26px;
        }
      }
    }
  }

  .dynamic_specsheet_box {
    .specsheet_box {
      table {
        td {
          min-width: 10em;
          border: 1px solid black;
          * {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
          div span {
            padding: 0.5em 1em;
          }
        }
        .specsheet_color_1 {
          background: var(--primary-color);
          color: var(--bg-white-color);
        }
        .specsheet_color_2 {
          background: $color-F5B071;
        }
        .specsheet_color_3 {
          background: $color-FFC295;
        }
        .specsheet_column_size_1 {
          min-width: 15em;
        }
        .specsheet_column_size_2 {
          min-width: 20em;
        }
        .specsheet_column_size_3 {
          min-width: 25em;
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    max-width: 18em;
  }
}

@media (max-width: 767.99px) {
  .prodinner_sec1 {
    .gallery_bg {
      height: 100%;
    }
    .gallery_breadcrumbs {
      padding-top: 5vh;
    }
    .product_banner_wrapper {
      padding-top: 12.5em;
      padding-bottom: 5.188em;
      top: 0;
      .product_img {
        width: 100%;
        max-width: 18.813em;
        display: flex;
        margin: 0 auto;
      }
      .product_heading {
        h1 {
          font-size: 2.5em;
        }
      }
      .details_box {
        .product_heading {
          h1 {
            @include textStyles(1.75em, normal, 0);
          }
        }
        .table_wrapper {
          td {
            font-size: 1em;
          }
        }
      }
    }
  }
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    max-width: 100%;
  }
  .prodinner_sec2 {
    .download_location {
      padding-left: 0;
      max-width: 100%;
      .share_wrap {
        margin: 3.5em 0 2.5em;
      }
    }
  }
}

@media (max-width: 600px) {
  .prodinner_sec1 {
    .product_banner_wrapper {
      padding-top: 6.5em;
      .product_heading {
        h1 {
          font-size: 1.75em;
        }
      }
      .details_box {
        .product_heading {
          h1 {
            font-size: 1em;
            margin-bottom: 1em;
          }
        }
        .MuiOutlinedInput-input {
          font-size: 12px;
        }
        .MuiSelect-icon {
          right: 1em;
          font-size: 1.75em;
        }
        .table_wrapper {
          margin: 1.25em 0 0;
          max-height: 100%;
          td {
            padding: 2px 8px 2px 8px;
            // padding: 2px 8px 2px 2.125em;
          }
          .first_box {
            padding-top: 13.5px;
          }
          .last_box {
            padding-bottom: 22.5px;
          }
        }
      }
    }
  }

  .prodinner_sec2 {
    padding: 2.5em 0;
    .text_container {
      .title_wrap {
        .title {
          font-size: 1.125em;
          line-height: normal;
        }
      }
      .description {
        font-size: 0.875em;
        line-height: 24px;
        margin: 0.75em 0 1.875em;
      }
      .accordion_wrap {
        .MuiAccordionDetails-root {
          padding: 0 0 0 22px;
          p {
            font-size: 14px;
            line-height: 30px;
            margin: 24px 0 0;
          }
        }
        .MuiAccordionSummary-root {
          min-height: 40px;
        }
        .accordion_summary {
          border-bottom: 1px solid $color-FFFFFF;
          .Mui-expanded {
            margin: 0 0 12px 0;
          }
          p {
            font-size: 1em;
            line-height: normal;
          }
        }
      }
    }
    .download_location {
      .download_box,
      .location_box {
        .title {
          font-size: 1em;
          line-height: normal;
          padding-bottom: 5px;
        }
        .description {
          font-size: 0.875em;
        }
        .download_share_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 2.5em 0 1.688em;
          .share_wrap {
            margin: 0;
            .share_icon {
              max-width: 30px;
              margin: 0 0.75em 0 0;
            }
            .share_indication {
              font-size: 14px;
            }
          }
        }
      }
      .download_box {
        margin-bottom: 2.5em;
      }
      .location_box {
        .title {
          margin-bottom: 1.688em;
        }
      }
    }
  }
}
