:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

@import "../../App.css";

.overseas_bg {
  height: 55.362987369577155vw;
  background-size: cover;
  position: relative;
  width: 100%;
  background-position: center;
}
.overseas_bg:before {
  content: "";
  position: absolute;
  opacity: 0.1;
  width: 100%;
  height: 100%;
}
.overseas_sec1 {
  .gallery_bg {
    height: 100vh;
    max-height: 100vh;
  }

  .overseas_heading {
    position: relative;
    top: 26.88358045030203vw;
    padding-left: 25.6447007138934655vw;

    h1 {
      max-width: 51.361889071938496vw;
      font: normal normal normal 5.305744096650192vw Secular One;
      background: var(--primary-color);
      border-bottom: 0.16474464579901152vw solid #f2ac6e;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-transform: uppercase;
      line-height: 4.6803953871499178vw;
      padding-bottom: 0.7589236683141132vw;
      margin-bottom: 0;
    }
  }
}
.overseas_sec2_bg {
  height: auto;
  background: #141414 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 6.85612300933553vw;
  padding-top: 7.707852828116421vw;
  padding-right: 6.6447007138934655vw;
  padding-bottom: 4vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.overseas-presence-s .presence-s_bg {
  padding-top: 9.571115vw;
}
.worldwideSec {
  display: flex;

  column-gap: 1rem;
}
.worldwideSec img {
  width: 249px;
  height: 104px;
}
.worldwideSec h1 {
  background: -webkit-linear-gradient(#ffe4cd, #f2ac6e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-weight: 700;
}
.worldwide-content {
  width: 45%;
}
.worldwide_content_para {
  font: normal normal 300 1.042vw Poppins;
}

.presence-s .overseas-presence-sec {
  display: flex;
  position: relative;
  padding-left: 7.628775vw;
  padding-right: 4.628775vw;
}
.presence-s .presence-s-count_sec .count-up p {
  width: 17vw;
}
.arrow_wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.locationBx {
  position: absolute;
  width: 21em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  box-shadow: -20px 20px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  right: 25%;
  top: 11%;
  padding: 10px 10px;
}
.locationBx h1 {
  color: #FFF;
  font-size: 24px;
  font: normal normal normal 24px/30px Secular One;
}
.locationBx p {
  text-align: center;
  font: normal normal 300 15px/25px Poppins;
}
.locationNameflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12rem;
  row-gap: 2rem;
  padding-bottom: 4vw;
}
.locationName1 {
  display: flex;
  flex-direction: column;
}
.locationName1 a {
  // color: #fff;
  color: var(--text-color);
  font: normal normal 300 1.042vw Poppins;
  text-decoration: underline;
}
.locationName1 a:hover {
  color: #ffc295;
}
.locationName1-desktop {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  -moz-column-gap: 15px;
  -webkit-column-gap: 15px;
  column-gap: 15px;
  list-style-type: none;
  width: 100%;
}
.locationName1-desktop li {
  color: var(--text-color);
  font: normal normal 300 1.042vw Poppins;
  text-decoration: underline;
}
// .locationName1-desktop li:hover {
//   color: var(--primary-color);
// }

.overseas-clients-section {
  padding-bottom: 4vw;
  padding-left: 4.628775vw;
  padding-right: 4.628775vw;
  padding-top: 4vw;
}
.overseas-clients-section h1 {
  // font-size: 50px;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: 2vw;
  font: normal normal normal 2.865vw Secular One;
}
.overseas-clients-section p {
  padding-top: 2vw;
  width: 85%;
  font: normal normal 300 1.042vw Poppins;
  color: var(--text-color);
}

.overseas-clients-section-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2rem;
  margin-top: 80px;
  align-items: center;
  padding-bottom: 50px;
}

.overseas_row {
  .clients-card {
    .blackWhite {
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .locationNameflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 6rem;
    row-gap: 2rem;
    padding-bottom: 4vw;
  }
  .overseas-clients-section h1 {
    font: normal normal normal 45px/30px Secular One;
  }
  .overseas-clients-section p {
    width: 100%;
    font: normal normal 300 18px/30px Poppins;
  }
}

@media (max-width: 1023px) {
  .locationName1 {
    padding-left: 50px;
  }
}

@media (max-width: 840px) {
  .overseas_sec1 .common_bg .common_text .heading {
    font: normal normal normal 7.305744vw Secular One;
    max-width: 70.361889vw;
  }
  .overseas_sec1 .common_bg .about_breadcrumbs {
    top: 14.916529vw;
  }
  .overseas_sec1 .common_bg .common_text {
    position: relative;
    top: 35.88358vw;
  }
  .locationBx {
    width: 12em;
    right: 10%;
    top: 18.5vw;
  }
  .locationBx p {
    font: normal normal 300 10px/15px Poppins;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 92%);
    width: calc(var(--swiper-navigation-size) / 40 * 600);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
  .swiper-button-next img,
  .swiper-button-prev img {
    width: 40px;
    height: 40px;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    display: none !important;
  }
  // .locationName1 {
  //   padding-left: 135px;
  // }
  .presence-s .count-up {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overseas_sec1 .overseas_heading {
    position: relative;
    top: 30.88358vw;
  }
  .presence-s .presence-s-count_sec .count-up p {
    width: 18vw;
  }
}

@media (max-width: 767px) {
  .arrow_wrapper {
    position: relative;
    justify-content: center;
    top: initial;
    left: initial;
    right: initial;
    .left_arrow, .right_arrow {
      max-width: 10.664vw;
      height: 10.644vw;
      margin: 0 15px;
    }
  }
}

@media (max-width: 600px) {
  .overseas_sec1 .common_bg .common_text {
    position: relative;
    top: 40.88358vw;
  }
  .overseas_sec1 .common_bg .common_text .heading {
    font: normal normal normal 5.905744vw Secular One;
    max-width: fit-content;
  }
  .locationBx {
    width: 9em;
    right: 10px;
    top: 8%;
  }
  .locationBx h1 {
    color: #ffc295;
    font: normal normal normal 15px/30px Secular One;
  }
  .locationBx p {
    font: normal normal 300 8px/10px Poppins;
  }
  .locationName1 {
    padding-left: 36px;
  }
  .locationName1 a {
    font: normal normal normal 14px/30px Poppins;
  }

  .overseas_presence_img_sec {
    padding-top: 5vw;
  }
  .presence-s .count-up {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overseas_sec1 .overseas_heading {
    position: relative;
    top: 40.88358vw;
  }
  .presence-s .presence-s-count_sec .count-up p {
    width: 32vw;
  }
  .presence-s .presence-s-count_sec .count-up h1 {
    margin-top: 15px;
  }
  .overseas-clients-section h1 {
    // font-size: 50px;
    color: #ffc295;
    border-bottom: 1px solid #fff;
    padding-bottom: 2vw;
    font: normal normal normal 6.399vw Secular One;
    line-height: 2rem !important;
  }
  .overseas-clients-section p {
    padding-top: 2vw;
    width: 85%;
    font: normal normal 300 3.74vw Poppins;
  }
  .col-3 {
    flex: 0 0 auto;
    // width: 23% !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 92%);
    width: calc(var(--swiper-navigation-size) / 44 * 290);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
  .swiper-button-next img,
  .swiper-button-prev img {
    width: 40px;
    height: 40px;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    display: none !important;
  }
  .overseas-clients-section {
    padding-left: 15px;
    padding-right: 15px;
  }
}
