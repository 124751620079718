:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

@import "../../../common.scss";

.mySwiper {
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: fit-content;
    height: fit-content;
    position: relative;
  }
  .prod_img {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 35.75em;
  }
  .swiper-pagination {
    padding-top: 3em !important;
    height: 6vw !important;
  }

  .swiper-pagination-bullet {
    // width: 1.573vw;
    // height: 1.573vw;
    width: 0.573vw;
    height: 0.573vw;
    text-align: center;
    // line-height: 20px;
    // font-size: 0.625vw;
    font-size: 0vw;
    color: #fff;
    opacity: 1;
    background: #fff;
    margin: 0 1.380208333333333vw;
    font-size: 0vw;
    color: #fff;
    opacity: 1;
    background: #fff;
    margin: 0 1.380208333333333vw;

    position: relative;
    &::before {
      content: "";
      width: 6.573vw;
      height: 6.573vw;
      position: absolute;
      top: 50%;
      right: -1.5vw;
      transform: translate(-50%, -50%);
      background-image: url("../../assets/image/testing/line.svg");
      object-fit: contain;
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: transparent;
    font: normal normal 300 1.042vw Poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: 8.75vw !important;
    margin-right: 0 !important;
    position: relative;
    &::before {
      display: block;
    }
  }
}
.arrow_wrapper {
  position: absolute;
  top: -3em;
  bottom: 0;
  left: 2.77vw;
  right: 2.77vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  .left_arrow,
  .right_arrow {
    width: 100%;
    max-width: 3.4377vw;
    height: 3.4377vw;
    object-fit: contain;
    display: block;
    cursor: pointer;
    z-index: 1;
  }
}
.testing_sec1 {
  position: relative;
  .common_bg {
    .common_text {
      .heading {
        // max-width: 47.361889vw;
        max-width: fit-content;
      }
    }
  }
  .icon_container {
    position: absolute;
    top: 34vw;
    // top: 55vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 3.3684vw;
    .icon_img {
      display: block;
      width: 100%;
      max-width: 6.4588vw;
      margin-left: 1.666666vw;
      margin-right: 1.666666vw;

      // border: 2px solid #f2ac6e;
      // border-radius: 50%;
      // padding: 1.094vw 1.2vw;
      // &:nth-child(2) {
      //   max-width: 8.9066vw;
      // }
    }
    .icon_img1 {
      max-width: 8.9066vw;
    }
  }
}

.testing_sec2 {
  // padding: 6.146vw 0 4.71355vw;
  padding: 9.146vw 0 4.94797vw;
  background: var(--bg-white-color);
  // min-height: 107.375em;

  .content_container {
    padding: 0 6.3022vw 0;
    .heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-align: left;
      border-bottom: 0.1647446458vw solid var(--primary-color);
      margin: 0;
      max-width: 26vw;
    }
    .text {
      font: normal normal 300 1.042vw Poppins;
      margin: 2.24vw 0 3.75vw;
      color: var(--text-color);
    }
  }

  .testing_slider {
    position: relative;
    .swiper {
      width: 100%;
    }
    .mySwiper {
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: fit-content;
        height: fit-content;
        position: relative;
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 35.75em;
      }
      .swiper-pagination {
        padding-top: 3em !important;
        height: 6vw !important;
      }
      .swiper-pagination-bullet {
        // width: 1.573vw;
        // height: 1.573vw;
        width: 0.573vw;
        height: 0.573vw;
        text-align: center;
        // line-height: 20px;
        // font-size: 0.625vw;
        font-size: 0vw;
        color: #fff;
        opacity: 1;
        background: #fff;
        margin: 0 1.380208333333333vw;
        font-size: 0vw;
        color: var(--text-color);
        opacity: 1;
        background: var(--text-color);
        margin: 0 1.380208333333333vw;

        position: relative;
        &::before {
          content: "";
          width: 6.573vw;
          height: 6.573vw;
          position: absolute;
          top: 50%;
          right: -1.5vw;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/image/testing/line.svg");
          object-fit: contain;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          display: none;
        }
      }
      .swiper-pagination-bullet-active {
        color: var(--text-color);
        background: transparent;
        font: normal normal 300 1.042vw Poppins;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-left: 8.75vw !important;
        margin-right: 0 !important;
        position: relative;
        &::before {
          display: block;
        }
      }
      // .testing_content {
      //   //   transform: translateY(-15%);
      //   .testing_img {
      //     width: 100%;
      //     height: 100%;
      //     // min-height: 48.75em;
      //   }
      // }

      // .testing_details {
      //   font: normal normal 300 1.042vw Poppins;
      //   margin: 1.25vw 0 1.25vw;
      //   padding: 0 3.5vw;
      //   text-align: center;
      //   display: none;
      // }

      // .swiper-slide.swiper-slide-visible.swiper-slide-next,
      // .swiper-slide.swiper-slide-visible.swiper-slide-prev {
      //   transform: translate3d(0px, 0px, -483.177px) rotateX(0deg) rotateY(0deg)
      //     scale(0.5331) !important;
      // }

      // .swiper-slide {
      //   transform: translate3d(0px, 0px, -1200.177px) rotateX(0deg)
      //     rotateY(0deg) scale(0.3523) !important;
      // }

      // .swiper-slide.swiper-slide-visible {
      //   margin-bottom: 2em;
      // }

      // .swiper-slide.swiper-slide-visible.swiper-slide-active {
      //   transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg)
      //     scale(1) !important;
      //   .testing_details {
      //     display: block;
      //   }
      //   // .testing_content {
      //   //   transform: translateY(0);
      //   // }
      // }

      // .swiper-pagination,
      // .slick-dots {
      //   padding-top: 1.75vw !important;
      //   // padding-top: 0.5vw !important;
      //   // height: auto !important;
      //   height: 4vw !important;
      // }

      // .swiper-pagination-bullet {
      //   // width: 1.573vw;
      //   // height: 1.573vw;
      //   width: 0.573vw;
      //   height: 0.573vw;
      //   text-align: center;
      //   // line-height: 20px;
      //   // font-size: 0.625vw;
      //   font-size: 0vw;
      //   color: #fff;
      //   opacity: 1;
      //   background: #fff;
      //   margin: 0 1.380208333333333vw;

      //   position: relative;
      //   &::before {
      //     content: "";
      //     width: 6.573vw;
      //     height: 6.573vw;
      //     // width: 4.573vw;
      //     // height: 4.573vw;
      //     position: absolute;
      //     // border-radius: 50%;
      //     // border: 3px solid #fff;
      //     // padding: 20px;
      //     top: 50%;
      //     // left: 50%;
      //     // right: -0.5vw;
      //     right: -1.5vw;
      //     transform: translate(-50%, -50%);
      //     background-image: url("../../assets/image/testing/line.svg");
      //     object-fit: contain;
      //     background-position: center;
      //     background-size: 100%;
      //     background-repeat: no-repeat;
      //     display: none;
      //   }
      // }

      // .swiper-pagination-bullet-active {
      //   color: #fff;
      //   background: transparent;
      //   // font-size: 1.2vw;
      //   font: normal normal 300 1.042vw Poppins;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   // border: 1px solid #631010;
      //   // border-radius: 50px;
      //   margin: 0;
      //   // margin-left: 5.75vw;
      //   margin-left: 8.75vw !important;
      //   margin-right: 0 !important;
      //   position: relative;
      //   &::before {
      //     display: block;
      //   }
      // }
    }
    .arrow_wrapper {
      position: absolute;
      top: -3em;
      bottom: 0;
      left: 2.77vw;
      right: 2.77vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 3.4377vw;
        height: 3.4377vw;
        object-fit: contain;
        display: block;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
}

.table_container {
  // background: #141414 0% 0% no-repeat padding-box;
  background-color: var(--bg-white-color);
  padding-bottom: 4.71355vw;
  .table_equipment {
    font: normal normal normal 2.865vw Secular One;
    background: var(--primary-color);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    text-align: left;
    // border-bottom: 0.1647446458vw solid #f2ac6e;
    // max-width: 26vw;
    text-align: center;
    margin: 0 auto;
    // padding: 4.94798vw 0 3.125vw;
    padding-bottom: 3.125vw;
  }
  .testing_table {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .table_content {
    background: var(--bg-white-color);
    border: 1px solid var(--primary-color);
    // width: 100%;
    width: 69.219vw;
    th {
      border: 1px solid var(--primary-color);
    }
    td {
      border-right: 1px solid var(--primary-color);
    }
    .table_heading,
    .table_heading1 {
      font-family: "Poppins";
      font-weight: 400;
      color: var(--primary-color);
      font-size: 1.25vw;
      padding: 0.521vw 0 0.521vw 4.166666vw;
      text-transform: uppercase;
    }
    .table_heading {
      width: 40%;
      // max-width: 40%;
    }
    .table_heading1 {
      width: 60%;
      // max-width: 60%;
    }
    .table_desc {
      color: var(--text-color);
      font-size: 1.045vw;
      font-family: "Poppins";
      // padding-left: 4.166666vw;
      padding: 1.224vw 0 1.224vw 4.166666vw;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
          padding: 0.521vw 0 0.521vw;
        }
      }
    }
  }
  .testing_table::-webkit-scrollbar {
    background-color: #656565;
    border-radius: 50px;
    height: 5px;
  }
  .testing_table::-webkit-scrollbar-thumb {
    background-color: #ffc295;
    border-radius: 50px;
  }
}

@media (max-width: 767px) {
  .testing_sec1 {
    position: relative;
    .common_bg {
      .common_text {
        top: 35vw;
        // .heading {
        //   max-width: 67.361889vw;
        // }
      }
    }
    .icon_container {
      top: 57vw;
      .icon_img {
        max-width: 10.4vw;
        // padding: 1.866vw;
        // &:nth-child(2) {
        //   max-width: 12vw;
        // }
      }
      .icon_img1 {
        max-width: 12vw;
      }
    }
  }

  .testing_sec2 {
    padding: 11.48vw 0 11.7277vw;
    min-height: auto;
    .content_container {
      padding: 0 4vw 0;
      .heading {
        font: normal normal normal 6.399vw Secular One;
        max-width: 57.5vw;
      }
      .text {
        font: normal normal 300 3.74vw Poppins;
        margin: 5.866vw 0 21.589vw;
      }
    }

    .testing_slider {
      .mySwiper {
        .swiper-slide {
          max-width: 66.9vw;
        }
        .testing_details {
          font: normal normal 300 3.74vw Poppins;
          margin: 4.266vw 0 7.996vw;
        }
        .swiper-slide img {
          height: auto;
          max-height: auto;
        }
        .swiper-slide.swiper-slide-visible.swiper-slide-next,
        .swiper-slide.swiper-slide-visible.swiper-slide-prev {
          transform: translate3d(0px, 0px, -483.177px) rotateX(0deg)
            rotateY(0deg) scale(0.8555) !important;
        }
        .swiper-pagination,
        .slick-dots {
          height: 4.5vw !important;
        }
        .swiper-pagination-bullet {
          width: 1.75vw;
          height: 1.75vw;
          margin: 0px 2.380208vw;
          &::before {
            width: 15.573vw;
            height: 15.573vw;
            right: -2.5vw;
          }
        }
        .swiper-pagination-bullet-active {
          font: normal normal 300 3.74vw Poppins;
          margin-left: 19vw !important;
        }

        // .testing_content {
        //   transform: translateY(-15%);
        //   .testing_img {
        //     min-height: auto;
        //   }
        // }
        // .testing_details {
        //   font: normal normal 300 3.74vw Poppins;
        //   margin: 4.266vw 0 7.996vw;
        // }
        // .swiper-slide.swiper-slide-visible.swiper-slide-next,
        // .swiper-slide.swiper-slide-visible.swiper-slide-prev {
        //   transform: translate3d(0px, 0px, -483.177px) rotateX(0deg)
        //     rotateY(0deg) scale(0.8555) !important;
        // }
        // .swiper-slide.swiper-slide-visible.swiper-slide-active {
        //   .testing_content {
        //     transform: translateY(0);
        //   }
        // }
        // .swiper-pagination,
        // .slick-dots {
        //   height: 4.5vw !important;
        // }
        // .swiper-pagination-bullet {
        //   width: 1.75vw;
        //   height: 1.75vw;
        //   margin: 0px 2.380208vw;
        //   &::before {
        //     width: 15.573vw;
        //     height: 15.573vw;
        //     right: -2.5vw;
        //   }
        // }
        // .swiper-pagination-bullet-active {
        //   font: normal normal 300 3.74vw Poppins;
        //   margin-left: 19vw !important;
        // }
      }
      .arrow_wrapper {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        justify-content: center;
        gap: 9.25vw;
        // margin-top: 7.996vw;
        .left_arrow,
        .right_arrow {
          max-width: 10.666vw;
          height: 10.666vw;
        }
      }
    }
  }
  .table_container {
    padding-bottom: 11.7277vw;
    padding-left: 15px;
    .table_equipment {
      font: normal normal normal 6.399vw Secular One;
      max-width: 70vw;
      // padding: 11.7277vw 0 11.7277vw;
      padding-bottom: 9.062vw;
    }
    .testing_table {
      display: block;
      margin: 0;
    }
    .table_content {
      width: 48em;
      margin-bottom: 2.25em;
      .table_heading,
      .table_heading1 {
        font-size: 4.8vw;
        font-weight: 500;
      }
      .table_heading {
        width: 20.5em;
      }
      .table_heading1 {
        width: 27.5em;
      }
      .table_heading,
      .table_heading1 {
        // padding: 2.133vw 0 2.133vw 9.33vw;
        padding: 2.133vw 0 2.133vw 7.5vw;
      }
      .table_desc {
        // font: normal normal 300 2.1vw Poppins;
        font: normal normal 300 3.74vw Poppins;
        // padding: 4.666vw 0 9.33vw 7.33vw;
        padding: 2.1322vw 0 2.1322vw 9.3284vw;
        ul {
          li {
            padding: 2.133vw 0 2.133vw;
          }
        }
      }
      .content:nth-child(2) .table_desc {
        padding: 6.69vw 0 2.1322vw 9.3284vw;
      }
    }
  }
}
