:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: 0.4375em !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.MuiMenu-paper {
  /* background-color: #fff; */
  background: rgba(0, 0, 0, 0.83) !important;
  // opacity: 1 !important;
  backdrop-filter: blur(50px) !important;
}
.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  max-width: calc(100% - 32px);
}
.MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  background: transparent !important;
  color: var(--primary-color) !important;
  padding-bottom: 8px;
  outline: 0;
}
.nearest-airport {
  color: var(--text-color);
  font: normal normal 300 1.042vw Poppins;
  letter-spacing: 1px;
  opacity: 0.8;
}
.japiur-drop {
  .MuiSelect-select {
    height: auto;
    min-height: 0.4375em !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.error_message {
  position: absolute;
  bottom: -1.5em;
  font-size: 1em;
  margin: 0;
}

.contact_sec1 {
  .contact_bg {
    height: 55.362987369577155vw;
    background-size: cover;
    position: relative;
    width: 100%;
    background-position: center;
  }

  .contact_bg:before {
    content: "";
    position: absolute;
    // background: #141414 0% 0% no-repeat padding-box;
    opacity: 0.75;
    width: 100%;
    height: 100%;
  }

  .contact_breadcrumbs {
    position: relative;
    top: 11.916529vw;
    padding-left: 5.1447007138934655vw;
    z-index: 2;
  }

  .contact_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    // margin-top: 10vw;
  }

  .contact_heading h1 {
    font: normal normal normal 4.305744096650192vw Secular One;
    background: var(--bg-white-color);
    border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-transform: uppercase;
    max-width: fit-content;
    line-height: 4.6803953871499178vw;
    // padding-bottom: 0.7589236683141132vw;
    margin-bottom: 0;
  }
}

.contact_sec2 {
  background: var(--bg-white-color);
  padding: 7.292vw 6.3022vw 3.671875vw;

  .contact_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .contact_col {
      .address_section {
        .contact-heading {
          font: normal normal normal 2vw Poppins;
          font-weight: 400;
          background: var(--primary-color);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          text-align: left;
          margin: 0;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 8.54375vw;
            height: 1.5px;
            background-color: var(--primary-color);
            border-radius: 50px;
          }
        }

        .heading {
          font: normal normal normal 2.865vw Secular One;
          background: var(--primary-color);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          text-align: left;
          // border-bottom: 0.1647446458vw solid #f2ac6e;
          margin: 0;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 12.34375vw;
            height: 2.5px;
            background-color: var(--primary-color);
            border-radius: 50px;
          }
        }

        .address_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .address_wrapper2 {
            .address_wrapper {
              padding-top: 1vw;
            }
            margin-top: 40px;
            .contact-inner-bx {
              display: flex;
              flex-wrap: wrap;
              margin-top: 20px;
              .sub_headings {
                margin-left: 2em;
                font-size: 1.25vw;
                font-family: "Poppins";
                letter-spacing: 0;
                line-height: 1.458;
                font-weight: 300;
                margin-bottom: 0.6vw;
                color: var(--text-color);
              }
            }
            .contact-subinner-bx {
              margin-top: 20px;
            }
          }
          .address_wrapper {
            .link:hover {
              color: inherit;
              text-decoration: none;
            }

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: var(--text-color);
            padding-top: 2.25vw;

            .location_img {
              display: block;
              width: 1.35vw;
              height: 1.9vw;
              max-width: 100%;
              object-fit: contain;
              margin-right: 1.79vw;
            }

            .phone_img {
              width: 1.15vw;
              height: 1.15vw;
              margin-right: 1.5vw;
              max-width: 100%;
              object-fit: contain;
            }

            .mail_img {
              width: 1.75vw;
              height: 1.25vw;
              margin-right: 1.4vw;
              background-size: cover;
            }

            .linkedin_img {
              width: 2.1vw;
              height: 2.1vw;
              margin-right: 1vw;
              background-size: cover;
            }

            .sub_heading {
              font-size: 1.25vw;
              font-family: "Poppins";
              letter-spacing: 0;
              line-height: 1.458;
              font-weight: 300;
              margin-bottom: 0.6vw;
              color: var(--text-color);
            }

            .address {
              color: var(--text-color);
              font: normal normal 300 1.042vw Poppins;
              letter-spacing: 1px;
              opacity: 0.8;
            }

            .linkedin_link {
              display: block;
              font: normal normal 300 1.042vw Poppins;
              text-decoration: underline;
              color: #ffff;
              margin-bottom: 0.3125vw;
              opacity: 0.9;
            }
          }

          .career-text p {
            padding-top: 2.1vw;

            a {
              font-size: 1.25vw;
              font-family: "Poppins";
              letter-spacing: 0;
              line-height: 1.458;
              font-weight: 300;
              color: var(--primary-color);
              text-decoration: underline;
            }

            span {
              color: #fff;
              font-size: 1.25vw;
              line-height: 1.458;
            }
          }
        }
      }
    }

    .contact_col {
      iframe {
        width: 43.35vw;
        height: 30.15vw;
        max-width: 100%;
      }
    }
  }

  .travel-row {
    padding-top: 4.025vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .travel-distance {
      display: flex;
      align-items: center;
      .heading {
        color: var(--primary-color);
        font: normal normal normal 2vw Secular One;
        .IoIosArrowDown {
          font-size: 15px;
          margin-bottom: 20px;
          color: var(--text-color);
        }
      }
    }

    .travel-duration {
      display: flex;
      gap: 5.1vw;
      align-items: center;

      .travel-type {
        .plane {
          width: 2.25vw;
          height: 2.25vw;
          margin-right: 0.866499vw;
        }

        .train {
          width: 1.75vw;
          height: 2.45vw;
          margin-right: 0.95vw;
        }

        .car {
          width: 2.25vw;
          height: 2.05vw;
          margin-right: 0.95vw;
        }

        span {
          font-size: 1.25vw;
          font-family: "Poppins";
          letter-spacing: 0;
          line-height: 1.458;
          font-weight: 300;
          color: var(--text-color);
        }
      }
    }
  }
}

.css-mpmdne-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
  .MuiOutlinedInput-input {
  color: var(--text-color) !important;
}

.dropdown {
  width: 0.95vw;
  height: 0.95vw;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
}
.contact_sec3 {
  background: #fdf8f5;
  .my_container {
    padding-top: 3.65vw;
    padding-bottom: 5.45vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      margin: 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 2px solid var(--primary-color);
    }

    .contact-form {
      padding-top: 2.85vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .contact_form_row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.65vw;

        .input_wrapper {
          width: 100%;
          position: relative;
          .inp_container {
            width: 100%;
            min-width: 500px;
            height: 3.65vw;
            border: 1px solid var(--primary-color);
            background: var(--bg-white-color);
            margin: 0;
            .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
              .MuiOutlinedInput-input {
              padding: 0.75em 1.05vw !important;
              font: normal normal normal 0.85vw Poppins !important;
              color: #a1a1a1;
            }
            .MuiOutlinedInput-input {
              padding: 0.75em 1.05vw !important;
              font: normal normal normal 0.85vw Poppins !important;
              color: #a1a1a1;
            }
            .MuiOutlinedInput-input {
              padding: 0.75em 1.05vw !important;
              font: normal normal normal 0.85vw Poppins !important;
            }
            .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
              .MuiOutlinedInput-input {
              padding: 0.75em 1.05vw !important;
              font: normal normal normal 0.85vw Poppins !important;
              color: #a1a1a1;
            }
          }
          .input_container {
            // width: 37.5vw;
            width: 100%;
            height: 3.65vw;
            max-width: 100%;
            border: 1px solid var(--primary-color);
            opacity: 1;
            position: relative;
            background: var(--bg-white-color);

            input {
              width: 100%;
              height: 100%;
              background: transparent;
              padding-left: 1.05vw;
              text-align: left;
              font: normal normal normal 0.85vw Poppins;
              letter-spacing: 0px;
              color: var(--text-color);
              opacity: 1;
              border: none;
              outline: none;
            }
            Select {
              MenuItem {
                width: 100%;
                height: 100%;
                background: var(--bg-white-color);
                padding-left: 1.05vw;
                text-align: left;
                font: normal normal normal 17px Poppins;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 0.2;
                border: none;
                outline: none;
                appearance: none;
                position: relative;
              }
            }
          }

          .input_container1 {
            // width: 37.5vw;
            height: 14.899vw;
            max-width: 100%;
            opacity: 1;
            background: var(--bg-white-color);

            textarea {
              width: 100%;
              height: 100%;
              background: var(--bg-white-color);
              padding-left: 1.05vw;
              padding-top: 1.4vw;
              text-align: left;
              font: normal normal 1.3vw 0.85vw Poppins;
              letter-spacing: 0px;
              color: var(--text-color);
              opacity: 1;
              border: 1px solid var(--primary-color);
              outline: none;
            }
          }

          .input_container2 {
            .checkbox {
              display: flex;
              align-items: flex-start;

              .input {
                width: 0.95vw;
                height: 0.95vw;
                margin-right: 0.65vw;
                border: none;
                outline: none;

                .consent_input {
                  width: 0.95vw;
                  height: 0.95vw;
                  cursor: pointer;
                }
              }

              p {
                color: var(--text-color);
                text-align: left;
                font: normal normal 1.3vw 0.85vw Poppins;
                opacity: 0.5;

                a {
                  opacity: 0.8;
                  // color: #ffffff;
                  color: inherit;
                  text-decoration: underline;
                }
              }
            }
          }

          .submit-button button {
            padding: 0.8vw 1.65vw;
            background: var(--primary-color);
            border-radius: 50px;
            border: none;
            color: #ffffff;
            text-align: left;
            font: normal normal 300 20px Poppins;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;

            img {
              margin-left: 27px;
              width: 18px;
              height: 15px;
            }
          }

          .input_container4 {
            width: 100%;
            height: 3.65vw;
            max-width: 100%;
            border: 1px solid var(--primary-color);
            opacity: 1;
            position: relative;
            display: flex;
            background: var(--bg-white-color);

            .select1 {
              width: 15%;
              height: 100%;
              position: relative;
              display: flex;
              justify-content: space-between;
              background: transparent;

              .select-section {
                width: 100%;
                height: 100%;
                position: relative;
                background: transparent;
                padding-left: 1.05vw;
                text-align: left;
                font: normal normal normal 0.85vw Poppins;
                letter-spacing: 0px;
                color: #ffffff;
                border: none;
                outline: none;
                appearance: none;
                position: relative;
                z-index: 1;

                option {
                  font: normal normal normal 0.85vw Poppins;
                  color: #ffffff;
                  opacity: 0.8;
                  border: none;
                  outline: none;
                  appearance: none;
                }
                .notranslate {
                  display: none;
                }
              }

              .dropdown1 {
                width: 0.95vw;
                height: 0.95vw;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                align-self: center;
                z-index: 1;
              }
            }

            input {
              width: 100%;
              height: 100%;
              background: var(--bg-white-color);
              padding-left: 1.05vw;
              text-align: left;
              font: normal normal normal 0.85vw Poppins;
              letter-spacing: 0px;
              color: var(--text-color);
              opacity: 0.8;
              border: none;
              outline: none;
            }
          }
        }
        .submit_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
    .css-1636szt,
    .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
      display: none !important;
    }
  }
}
@media (max-width: 1440px) {
  .error_message {
    font-size: 0.75em;
  }
  .contact_sec3 {
    .my_container {
      .contact-form {
        .contact_form_row {
          .input_wrapper {
            .input_container2 {
              .checkbox {
                .input {
                  width: 1.125vw;
                  height: 1.125vw;
                  .consent_input {
                    width: 1.125vw;
                    height: 1.125vw;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .contact_sec3 .my_container .contact-form .contact_form_row {
    display: block;
    grid-template-columns: 1fr;
    .input_wrapper {
      margin-bottom: 2em !important;
      .input_container,
      .input_container4,
      .inp_container {
        width: 100%;
        height: 48px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .contact_sec3 {
    .my_container {
      .contact-form {
        margin: 0 20%;
        .contact_form_row {
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .contact_sec1 .contact_bg {
    height: 85.362987vw;
    background-size: contain;
    position: relative;
    width: 100%;
    background-position: center;
  }
  .sub_headings {
    font-size: 2.25vw !important;
  }
  .contact_sec1 .contact_breadcrumbs {
    position: relative;
    top: 13.916529vw;
    padding-left: 5.1447007139vw;
    z-index: 2;
  }
}
@media (max-width: 768px) {
  .contact_sec2 {
    .contact_row {
      flex-direction: column;
      justify-content: space-between;

      .contact_col {
        width: 100%;

        .address_section {
          .heading {
            font: normal normal normal 6.399vw Secular One;

            &::before {
              max-width: 73%;
            }
          }

          .address_container {
            padding-top: 45px;
            flex-direction: column;

            .address_wrapper {
              .content_img {
                .location_img {
                  width: 15px;
                  height: 21px;
                  max-width: 100%;
                  height: auto;
                  margin-right: 40px;
                }

                .phone_img {
                  width: 20px;
                  height: 20px;
                  max-width: 100%;
                  height: auto;
                  margin-right: 22px;
                }

                .mail_img {
                  width: 23px;
                  height: 15px;
                  max-width: 100%;
                  height: auto;
                  margin-right: 20px;
                }

                .linkedin_img {
                  width: 28px;
                  height: 28px;
                  max-width: 100%;
                  height: auto;
                  margin-right: 20px;
                }
              }

              .content_wrapper {
                margin-bottom: 20px;
                .sub_heading {
                  font-size: 16px;
                  line-height: 25px;
                  margin-bottom: 12px;
                }

                .address {
                  font-size: 14px;
                  line-height: 24px;
                }

                .linkedin_link {
                  font-size: 16px;
                  line-height: 25px;
                }
              }
            }

            .career-text p {
              a {
                font-size: 16px;
                line-height: 25px;
              }

              span {
                font-size: 16px;
                line-height: 25px;
              }
            }
          }
        }
      }

      .contact_col {
        width: 100%;

        iframe {
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }
    }

    .travel-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .travel-distance {
        .heading {
          font-size: 20px;
          line-height: 7.16vw;
        }
      }

      .travel-duration {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 4.94vw;

        .travel-type {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .plane {
            width: 32px;
            height: 32px;
          }

          .train {
            width: 25px;
            height: 29px;
          }

          .car {
            width: 32px;
            height: 29px;
          }

          span {
            font-size: 14px;
            line-height: 21px;
          }

          .plane,
          .car,
          .train {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contact_sec3
    .my_container
    .contact-form
    .contact_form_row
    .input_wrapper
    .inp_container {
    width: 100%;
    min-width: fit-content;
    height: 12.65vw;
    margin: 0;
  }
  .contact_sec3
    .my_container
    .contact-form
    .contact_form_row
    .input_wrapper
    .inp_container
    input {
    padding-left: 20px;
    font-size: 14px;
    line-height: 21px;
  }
  .contact_sec3 {
    display: flex;
    justify-content: center;
    .my_container {
      display: flex;
      justify-content: center;

      h2 {
        font: normal normal normal 6.399vw Secular One;
      }

      .contact-form {
        display: flex;
        justify-content: center;
        padding: 4.81vw 0;
        margin: 0;
        width: 100%;

        .contact_form_row {
          display: block;
          width: 100%;
          grid-template-columns: 1fr;

          .input_wrapper {
            width: 100%;
            height: 100%;

            .input_container {
              width: 100%;
              height: 48px;

              input {
                padding-left: 20px;
                font-size: 14px;
                line-height: 21px;
              }

              select {
                padding-left: 20px;
                font-size: 14px;
                line-height: 21px;

                option {
                  padding-left: 20px;
                  font-size: 14px;
                  line-height: 21px;
                }
              }

              .dropdown {
                width: 14px;
                height: 14px;
              }
            }

            .input_container1 {
              width: 100%;
              height: 163px;

              textarea {
                padding-left: 20px;
              }
            }

            .input_container4 {
              width: 100%;
              height: 48px;

              .select1 {
                width: 20%;
                height: 100%;

                .select-section {
                  height: 100%;
                  padding-left: 20px;
                  font-size: 14px;
                  line-height: 21px;

                  option {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }

                .dropdown1 {
                  width: 14px;
                  height: 14px;
                }
              }

              input {
                // padding-left: 20px;
                font-size: 14px;
                line-height: 21px;
              }
            }

            .input_container2 {
              .checkbox {
                .input {
                  margin-right: 12px;
                }
              }

              p {
                a {
                  color: #ffffff;
                  opacity: 0.8;
                  text-decoration: underline;
                }
              }
            }

            .submit-button button {
              font-size: 14px;
              padding: 10px 20px;
              span {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 629px) {
  .contact_sec2 .contact_row {
    .contact_col {
      .address_section {
        .address_container {
          .address_wrapper2 {
            .contact-subinner-bx {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .contact_sec3
    .my_container
    .contact-form
    .contact_form_row
    .input_wrapper
    .inp_container
    .MuiOutlinedInput-input {
    padding: 0.75em 5.05vw !important;
    font: normal normal normal 3.5vw Poppins !important;
  }
  .dropdown {
    width: 3.5vw;
    height: 3.5vw;
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translate(-50%, -50%);
    align-self: center;
  }
  .nearest-airport {
    color: var(--text-color);
    font: normal normal 300 3.042vw Poppins;
    letter-spacing: 1px;
    opacity: 0.8;
  }
  .contact_sec2 .contact_row .contact_col .address_section .contact-heading {
    font: normal normal normal 6vw Poppins;
    font-weight: 400;
    // background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%) 0%
    //   0% no-repeat padding-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: left;
    margin: 0;
    position: relative;
  }
  .contact_sec1 .contact_breadcrumbs {
    position: relative;
    top: 29.916529vw;
    padding-left: 5.1447007139vw;
    z-index: 2;
  }
  .contact_sec1 .contact_heading {
    top: 11vh;
  }
  .contact_sec1 .contact_heading h1 {
    font: normal normal normal 7.305744vw Secular One;
    // background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%) 0%
    //   0% no-repeat padding-box;
    // border-bottom: 0.1647446458vw solid #f2ac6e;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-transform: uppercase;
    max-width: -moz-fit-content;
    max-width: fit-content;
    line-height: 6.680395vw;
    margin-bottom: 0;
  }
  .contact_sec2
    .contact_row
    .contact_col
    .address_section
    .contact-heading::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 27.54375vw;
    height: 1.5px;
    // background-color: #f5b071;
    border-radius: 50px;
  }
  .sub_headings {
    font-size: 3.25vw !important;
  }
  .contact_sec3 {
    .my_container {
      .contact-form {
        .contact_form_row {
          .input_wrapper {
            .input_container4 {
              .select1 {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  .select-input-jaipur {
    .MuiOutlinedInput-input {
      padding: 13.5px 14px;
    }
  }
  .contact_sec3 {
    .my_container {
      .contact-form {
        .contact_form_row {
          .input_wrapper {
            .input_container2 {
              .checkbox {
                .input {
                  width: 3.25vw;
                  height: 3.25vw;
                  .consent_input {
                    width: 3.25vw;
                    height: 3.25vw;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
// .css-1636szt,
// .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
//   display: none !important;
// }
.japiur-drop .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  display: block !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
