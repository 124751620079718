@import "../../../common.scss";

.privacy_sec1 {
  position: relative;
  .common_bg {
    .common_text {
      .heading {
        // max-width: 40vw;
        max-width: fit-content;
      }
    }
  }
}

.privacy_sec2 {
  background: var(--bg-white-color);
  padding: 8.875em 0 5.625em;
  .para_wrapper {
    margin-bottom: 6.4375em;
    &:last-child {
      margin-bottom: 0;
    }
    .heading {
      color: #ffc295;
      font-size: 1.75em;
      font-weight: 600;
      font-family: "Poppins";
      letter-spacing: 0px;
      line-height: 1.072;
      margin-bottom: 1.5em;
    }
    .para {
      //   color: #ffffff;
      color: var(--text-color);
      font-size: 1.25em;
      font-weight: 300;
      font-family: "Poppins";
      letter-spacing: 0px;
      line-height: 1.5;
      margin-bottom: 1.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .top_space {
      margin-top: 1.5em;
    }
    .list_wrapper {
      padding-left: 2em;
      margin-bottom: 0;
      li {
        // color: #ffffff;
        color: var(--text-color);
        font-size: 1.25em;
        font-weight: 300;
        font-family: "Poppins";
        letter-spacing: 0px;
        line-height: 1.5;
        margin-bottom: 0.8em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .privacy_link {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  .privacy_sec1 {
    .common_bg {
      .common_text {
        top: 37.5vw;
      }
    }
  }

  .privacy_sec2 {
    padding: 2.75em 0 2.75em;
    .para_wrapper {
      margin-bottom: 2.5em;
      .heading {
        font-size: 1em;
        line-height: 1.875;
        margin-bottom: 1.25em;
      }
      .para {
        font-size: 0.875em;
        line-height: 1.714;
        margin-bottom: 1.28577em;
      }
      .list_wrapper {
        padding-left: 1.2em;
        li {
          font-size: 0.875em;
          line-height: 1.714;
          margin-bottom: 1em;
        }
      }
    }
  }
}
