$color-707070: #707070;
$color-FFFFFF: #ffffff;
$color-141414: #141414;
$color-F5B071: #f5b071;
$color-FFC295: #ffc295;

// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE

// section {
//   height: 100vh;
//   overflow-y: scroll;
// }

:target {
  scroll-margin-top: 200px;
}
// transform: scaleY(0); // implicit, but good to specify explicitly
::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--text-color) !important;
}

.notfound {
  background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%) 0%
    0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin linear-gradient($fromColor, $toColor) {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($fromColor),
    to($toColor)
  ); // Saf4+, Chrome
  background-image: -webkit-linear-gradient(
    top,
    $fromColor,
    $toColor
  ); // Chrome 10+, Saf5.1+, iOS 5+
  background-image: -moz-linear-gradient(top, $fromColor, $toColor); // FF3.6
  background-image: -ms-linear-gradient(top, $fromColor, $toColor); // IE10
  background-image: -o-linear-gradient(
    top,
    $fromColor,
    $toColor
  ); // Opera 11.10+
  background-image: linear-gradient(top, $fromColor, $toColor);
}
@mixin single-transition($property: all, $speed: 0.125s, $ease: linear) {
  -webkit-transition: $property $speed $ease;
  -moz-transition: $property $speed $ease;
  -o-transition: $property $speed $ease;
  transition: $property $speed $ease;
}

// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE
// MIXINS HERE

@media (min-width: 2560px) {
  .my_container {
    max-width: 2174.7px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 1646px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0;
  }
}
@media (max-width: 1920px) {
  .my_container {
    max-width: 1646px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  .my_container {
    max-width: 1304.8px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1440px) {
  .my_container {
    max-width: 1223.25px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  .my_container {
    max-width: 1160.38px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  .my_container {
    max-width: 1087.33px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  .my_container {
    max-width: 1014.27px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  .my_container {
    max-width: 708.46px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
