:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

@import "../../../common.scss";

.para_wraper {
  background: $color-141414;
}

.financial_sec1 {
  z-index: 10;
  .gallery_bg {
    height: 100vh;
    max-height: 100vh;
  }
  .financial_heading {
    position: relative;
    top: 16.88358045030203vw;
    padding-left: 6.6447007138934655vw;
    h1 {
      max-width: 27.361889071938496vw;
      font: normal normal normal 5.305744096650192vw Secular One;
      background: var(--bg-white-color);
      border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-transform: uppercase;
      line-height: 4.6803953871499178vw;
      padding-bottom: 1vw;
      // margin: 0 auto;
    }
  }
  .img_wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    .banner_copper_img {
      width: 100%;
      max-width: 70vw;
      max-height: 80vh;
      object-fit: contain;
      object-position: right;
    }
  }
  // .scroll-down-d {
  //   left: 6.6447007138934655vw;
  // }
}

.financial_sec2 {
  z-index: 10;
  .financial_tabs_bg {
    height: auto;
    background: var(--bg-white-color);
    opacity: 1;
    padding-left: 6.85612300933553vw;
    padding-top: 7.707852828116421vw;
    padding-right: 6.6447007138934655vw;
    .MuiTabs-root {
      min-height: 3.2948929159802307vw;
    }
    .MuiTabPanel-root {
      padding: 2.688rem 0 0;
    }
    .MuiTab-textColorPrimary.Mui-selected {
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      font: normal normal normal 2.865vw Secular One;
      text-transform: uppercase;
      //   max-width: 50.049972542559033vw;
      padding: 0;
      height: auto;
      max-width: 70%;
    }
    .MuiTab-textColorPrimary {
      font: normal normal normal 1.098297638660077vw Secular One;
      letter-spacing: 0px;
      color: #c1c1c1;
      text-transform: uppercase;
      max-width: 100%;
      padding: 0;
      height: 1.9220208676551345vw;
      line-height: 1.6474464579901154vw;
    }
    .MuiTabs-indicator {
      background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%)
        0% 0% no-repeat padding-box;
      -webkit-text-fill-color: transparent;
      margin-top: 0.9906644700713894vw;
      height: 0.10982976386600769vw;
    }
    .MuiTabs-flexContainer {
      /* justify-content: space-between; */
      gap: 3.2399780340472266vw;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #fff; /* or add it to the track */
      }
      &::-webkit-scrollbar-thumb {
        background: grey;
        margin: 2px 0;
      }
    }
    .category_desc {
      margin: 0;
      font: normal normal 300 1.042vw Poppins;
      padding: 0 0 5.313em;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      color: var(--text-color)
    }
    .documents_row {
      padding: 0;
      .col-md-3 {
        text-align: center;
        a {
          text-decoration: none;
          border: none;
        }
        &:nth-child(4n) .document_box {
          border: none;
        }
        &:last-child .document_box {
          border: none;
        }
        .document_box {
          border-right: 1px solid $color-707070;
          cursor: pointer;
          margin: 0 0 6.5rem;
          padding: 1.875rem 2.875em;
          .download_icon {
            border-radius: 50%;
            margin: 0 0 0.688rem;
          }
          .document_title {
            @include textStyles(1.25em, 1.375em, 0);
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            letter-spacing: 0px;
            color: var(--text-color);
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .financial_sec1 {
    padding-top: 8vh;
    .gallery_bg {
      height: 50vh;
      max-height: 50vh;
    }
  }

  .financial_sec2 {
    .financial_tabs_bg {
      .MuiTab-textColorPrimary {
        font-size: 1em;
      }
      .MuiTab-textColorPrimary.Mui-selected {
        font-size: 1.5em;
      }

      .documents_row {
        .col-md-3 {
          &:nth-child(2n) .document_box {
            border: none;
          }
          &:last-child .document_box {
            border: none;
          }
          .document_box {
            padding: 1.875rem 0.5em;
            margin: 0 0 3.5rem;
            .document_title {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .financial_sec1 {
    .gallery_breadcrumbs {
      position: relative;
      top: 12.1333333333vw;
      padding-left: 4vw;
    }
    .gallery_bg {
      height: 18.75rem;
      max-height: 18.75rem;
    }
    .img_wrapper {
      .banner_copper_img {
        width: 100%;
        max-width: 55vw;
        max-height: initial;
        object-fit: contain;
        object-position: right;
      }
    }
    // .scroll-down-d {
    //   left: 6.6447007138934655vw;
    // }
    .financial_heading {
      top: 40vw;
      h1 {
        max-width: fit-content;
        font: normal normal normal 7.305744096650192vw Secular One;
        padding-bottom: 0;
        line-height: 1em;
      }
    }
  }
  .financial_sec2 {
    .financial_tabs_bg {
      padding: 43.88px 15px 40px;
      .MuiTabPanel-root {
        padding: 40px 0 0;
      }
      .MuiTab-textColorPrimary {
        font-size: 1em;
      }
      .MuiTab-textColorPrimary.Mui-selected {
        font-size: 1.5em;
      }

      .category_desc {
        font-size: 14px;
        padding: 0 0 40px;
      }

      .documents_row {
        padding: 0 15px;
        .col-md-3 {
          &:nth-child(1n) .document_box {
            padding-left: 0;
            padding-right: 1em;
          }
          &:nth-child(2n) .document_box {
            border: none;
            padding-left: 1em;
            padding-right: 0;
          }
          &:last-child .document_box {
            border: none;
          }
          .document_box {
            padding: 6px 0 0;
            margin: 0 0 40px;
            .download_icon {
              max-width: 32px;
            }
            .document_title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-height: 800px) {
  .financial_sec1 {
    .gallery_bg {
      height: 100vh;
      max-height: 100vh;
    }
  }
}
