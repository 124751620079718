:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

@import "../../../common.scss";

.manufacturing_sec1 {
  position: relative;
  .common_bg {
    .common_text {
      .heading {
        max-width: 67.361889vw;
      }
    }
  }
  .icon_container {
    position: absolute;
    top: 34vw;
    // top: 55vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 3.3684vw;
    .icon_img {
      display: block;
      width: 100%;
      max-width: 6.4588vw;
      margin-left: 1.666666vw;
      margin-right: 1.666666vw;

      // border: 2px solid #f2ac6e;
      // border-radius: 50%;
      // padding: 1.094vw 1.2vw;
      // &:nth-child(2) {
      //   max-width: 8.9066vw;
      // }
    }
    .icon_img1 {
      max-width: 8.9066vw;
    }
  }
}

.manufacturing_sec2 {
  padding-top: 9.146vw;
  // background: transparent
  //   radial-gradient(closest-side at 55% 43%, #483f3c 0%, #141414 100%) 0% 0%
  //   no-repeat padding-box;
  background-color: var(--bg-white-color);

  .content_container {
    padding: 0 6.3022vw 0;
    .heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-align: left;
      border-bottom: 0.1647446458vw solid #f2ac6e;
      margin: 0;
      max-width: 36vw;
    }
    .text {
      font: normal normal 300 1.042vw Poppins;
      // margin: 2.24vw 0 0;
      margin: 2.24vw 0;
      color: var(--text-color);
    }
  }

  .manufacturing_process {
    .maunfacutring_process_img {
      width: 100%;
      max-width: 100%;
    }
    .process_video {
      width: 100%;
      max-width: 100%;
      // object-fit: contain;
      // max-height: 134.625em;
      // height: fit-content;
      // margin-bottom: 50px;
    }
  }
  .innerpage_btn {
    // padding: 4.369vw 6.8229vw 4.26vw 6.3547vw;
    padding: 4.26vw 6.8229vw 4.26vw 6.3547vw;
    .heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-align: left;
      margin-bottom: 0.63177vw;
    }
    .inner_btn_row {
      // justify-content: space-between;
      .inner_cta {
        position: relative;
        width: 100%;
        max-width: 19.323vw;
        // height: 3.2299vw;
        margin-top: 2.6042vw;
        text-align: center;
        border-radius: 50px;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: var(--primary-color);
          // border: none;
          display: block;
          width: 100%;
          // max-width: 19.323vw;
          max-width: 0;
          height: 3.2299vw;
          border-radius: 50px;
          transition: all 0.3s ease;
        }
        &:hover::before {
          max-width: 19.323vw;
        }
      }
      .inner_btn {
        border: 1px solid var(--primary-color);
        border-radius: 50px;
        font-size: 1.042vw;
        font-weight: 400;
        color: var(--text-color);
        // padding: 0.88544vw 0;
        padding: 0.79167vw 0;
        // width: 100%;
        // max-width: 19.323vw;
        // height: 3.2299vw;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 2.6042vw;
        position: relative;
        &:hover {
          // border: none;
          border: 1px solid var(--primary-color);
          color: var(--bg-white-color);
        }
      }
    }
  }
}

.manufacturing_sec3 {
  padding: 2.3375vw 0 4.583333vw;
  background: var(--bg-white-color);

  .content_container {
    padding: 0 6.3022vw 0;
    .heading {
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-align: left;
      //   border-bottom: 0.1647446458vw solid #f2ac6e;
      margin: 0;
      //   max-width: 36vw;
    }
    .text {
      font: normal normal 300 1.042vw Poppins;
      margin: 2.24vw 0 4.401vw;
      color: var(--text-color);
    }
  }

  .testing_slider {
    background: var(--bg-white-color);
    .main_slider_wrapper {
    }
    // .swiper {
    //   width: 100%;
    // }
    // .mySwiper {
    //   .swiper-slide {
    //     background-position: center;
    //     background-size: cover;
    //     width: 100%;
    //     max-width: 44.95vw;
    //   }
    //   .swiper-slide img {
    //     display: block;
    //     width: 100%;
    //     max-width: 100%;
    //     max-height: 48.75em;
    //   }

    //   // .testing_content {
    //   //   transform: translateY(-15%);
    //   // }

    //   .testing_details {
    //     font: normal normal 300 1.042vw Poppins;
    //     margin: 1.25vw 0 1.25vw;
    //     padding: 0 3.5vw;
    //     text-align: center;
    //     display: none;
    //   }

    //   .swiper-slide.swiper-slide-visible.swiper-slide-next,
    //   .swiper-slide.swiper-slide-visible.swiper-slide-prev {
    //     transform: translate3d(0px, 0px, -483.177px) rotateX(0deg) rotateY(0deg)
    //       scale(0.5331) !important;
    //   }

    //   .swiper-slide {
    //     transform: translate3d(0px, 0px, -1200.177px) rotateX(0deg)
    //       rotateY(0deg) scale(0.3523) !important;
    //   }

    //   .swiper-slide.swiper-slide-visible {
    //     margin-bottom: 2em;
    //   }

    //   .swiper-slide.swiper-slide-visible.swiper-slide-active {
    //     transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg)
    //       scale(1) !important;
    //     .testing_details {
    //       display: block;
    //     }
    //     // .testing_content {
    //     //   transform: translateY(0);
    //     // }
    //   }

    //   .swiper-pagination,
    //   .slick-dots {
    //     padding-top: 1.5vw !important;
    //     // padding-top: 0.5vw !important;
    //     // height: auto !important;
    //     height: 3vw !important;
    //   }

    //   .swiper-pagination-bullet {
    //     // width: 1.573vw;
    //     // height: 1.573vw;
    //     width: 0.573vw;
    //     height: 0.573vw;
    //     text-align: center;
    //     // line-height: 20px;
    //     // font-size: 0.625vw;
    //     font-size: 0vw;
    //     color: #fff;
    //     opacity: 1;
    //     background: #fff;
    //     margin: 0 1.380208333333333vw;

    //     position: relative;
    //     &::before {
    //       content: "";
    //       width: 5.573vw;
    //       height: 5.573vw;
    //       // width: 4.573vw;
    //       // height: 4.573vw;
    //       position: absolute;
    //       // border-radius: 50%;
    //       // border: 3px solid #fff;
    //       // padding: 20px;
    //       top: 50%;
    //       // left: 50%;
    //       // right: -0.5vw;
    //       right: -1.5vw;
    //       transform: translate(-50%, -50%);
    //       background-image: url("../../assets/image/testing/line.svg");
    //       object-fit: contain;
    //       background-position: center;
    //       background-size: 100%;
    //       background-repeat: no-repeat;
    //       display: none;
    //     }
    //   }

    //   .swiper-pagination-bullet-active {
    //     color: #fff;
    //     background: transparent;
    //     // font-size: 1.2vw;
    //     font: normal normal 300 1.042vw Poppins;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     // border: 1px solid #631010;
    //     // border-radius: 50px;
    //     margin: 0;
    //     // margin-left: 5.75vw;
    //     margin-left: 8.75vw !important;
    //     margin-right: 0 !important;
    //     position: relative;
    //     &::before {
    //       display: block;
    //     }
    //   }
    // }
    .main_slider_wrap {
      position: relative;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: center;
    }
    .swiper_image {
      .main_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: cover;
        background: #141414 0% 0% no-repeat padding-box;
      }
    }
    .thumnail_container {
      position: relative;
    }
    .swiper_thumnail {
      background-color: var(--bg-white-color);
      position: relative;
      // padding: 2.7083333vw 4.7917vw 4.011vw;
      // padding: 2.7083333vw 10.8854vw 4.011vw;
      padding: 2.7083333vw 0 4.011vw;
      width: 78.25%;
      .swiper-slide {
        width: 25%;
        // height: 100%;
        // max-width: 11.355vw;
        // margin: 0 1vw 0 1vw;
        opacity: 0.4;
      }
      .swiper-slide-thumb-active {
        opacity: 1;
        visibility: visible;
      }
      .thumnail_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .arrow_wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 4.77vw;
      right: 4.77vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 3.4377vw;
        height: 3.4377vw;
        object-fit: contain;
        display: block;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
}

.manufacturing_sec4 {
  // background: #141414 0% 0% no-repeat padding-box;
  background-color: var(--bg-white-color);
  padding-bottom: 4.66145vw;
  .table_equipment {
    font: normal normal normal 2.865vw Secular One;
    background: var(--primary-color);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: left;
    // border-bottom: 0.1647446458vw solid #f2ac6e;
    // max-width: 26vw;
    text-align: center;
    margin: 0 auto;
    // padding: 4.94798vw 0 3.125vw;
    padding-bottom: 2.708333vw;
    width: 100%;
    max-width: 67.1875vw;
  }
  .testing_table {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .table_content {
    background: var(--bg-white-color);
    border: 1px solid var(--primary-color);
    // width: 100%;
    width: 69.219vw;
    th {
      border: 1px solid var(--primary-color);
    }
    td {
      // border: 1px solid #ffc295;
      border-right: 1px solid var(--primary-color);
    }
    .table_heading,
    .table_heading1 {
      font-family: "Poppins";
      font-weight: 400;
      color: var(--primary-color);
      font-size: 1.25vw;
      padding: 0.521vw 0 0.521vw 4.166666vw;
      text-transform: uppercase;
    }
    .table_heading {
      width: 40%;
      // max-width: 40%;
    }
    .table_heading1 {
      width: 60%;
      // max-width: 60%;
    }
    .table_desc {
      color: var(--text-color);
      font-size: 1.045vw;
      font-family: "Poppins";
      //   padding: 1.745vw 0 3.359375vw 4.1666666vw;
      padding: 0.5208333333333333vw 0 0.5208333333333333vw 4.1666666vw;
      vertical-align: top;
    }
    .content:nth-child(1),
    .content:nth-child(2) {
      .table_desc {
        padding: 1.745vw 0 0.5208333333333333vw 4.1666666vw;
      }
    }
    .content:nth-last-child(1) {
      .table_desc {
        padding: 0.5208333333333333vw 0 3.359375vw 4.1666666vw;
      }
    }
  }
  .testing_table::-webkit-scrollbar {
    background-color: #656565;
    border-radius: 50px;
    height: 5px;
  }
  .testing_table::-webkit-scrollbar-thumb {
    background-color: #ffc295;
    border-radius: 50px;
  }
}

@media (max-width: 767px) {
  .manufacturing_sec1 {
    position: relative;
    .common_bg {
      .common_text {
        .heading {
          max-width: 60.361889vw;
        }
      }
    }
    .icon_container {
      top: 57vw;
      .icon_img {
        max-width: 10.4vw;
        // padding: 1.866vw;
        // &:nth-child(2) {
        //   max-width: 12vw;
        // }
      }
      .icon_img1 {
        max-width: 12vw;
      }
    }
  }

  .manufacturing_sec2 {
    // padding: 11.48vw 0 11.7277vw;
    padding: 11.48vw 0 0;
    .innerpage_btn {
      // padding: 0 10.13vw 1.866vw 10.13vw;
      padding: 3em 10.13vw 3em 10.13vw;
      .heading {
        font: normal normal normal 6.399vw Secular One;
        text-align: center;
      }
      .inner_btn_row {
        justify-content: center;
        margin: 0 auto;
        .inner_cta {
          max-width: 98.9%;
          margin-top: 8.266vw;
          &::before {
            display: none;
          }
          // &:hover::before {
          //  display: none;
          // }
        }
        .inner_btn_col {
          padding: 0;
          &:nth-child(2n + 1) {
            padding-right: 1.6vw;
          }
          &:nth-child(2n + 2) {
            padding-left: 1.6vw;
          }
        }
        .inner_btn_col:last-child {
          .inner_cta {
            // max-width: 57.41%;
            max-width: 63%;
            margin: 8.266vw auto 0;
          }
        }
        .inner_btn {
          // font: normal normal 300 3.2vw Poppins;
          font: normal normal 300 2.898297638660077vw Poppins;
          padding: 2.188vw 0;
          &:hover {
            border: 1px solid #f5b071;
          }
        }
      }
    }
    .content_container {
      padding: 0 4vw 0;
      .heading {
        font: normal normal normal 6.399vw Secular One;
        max-width: 53.5vw;
      }
      .text {
        font: normal normal 300 3.74vw Poppins;
        // margin: 5.866vw 0 21.589vw;
        margin: 5.866vw 0 6vw;
      }
    }
  }

  .manufacturing_sec3 {
    // padding: 11.48vw 0 11.7277vw;
    padding: 11.48vw 0 7.7277vw;
    .content_container {
      padding: 0 4vw 0;
      .heading {
        font: normal normal normal 6.399vw Secular One;
        max-width: 100%;
      }
      .text {
        font: normal normal 300 3.74vw Poppins;
        margin: 4.56vw 0 8.5555vw;
      }
    }

    .testing_slider {
      // .mySwiper {
      //   .swiper-slide {
      //     max-width: 66.9vw;
      //   }
      //   .testing_content {
      //     transform: translateY(-15%);
      //   }
      //   .testing_details {
      //     font: normal normal 300 3.74vw Poppins;
      //     margin: 4.266vw 0 7.996vw;
      //   }
      //   .swiper-slide.swiper-slide-visible.swiper-slide-next,
      //   .swiper-slide.swiper-slide-visible.swiper-slide-prev {
      //     transform: translate3d(0px, 0px, -483.177px) rotateX(0deg)
      //       rotateY(0deg) scale(0.8555) !important;
      //   }
      //   .swiper-slide.swiper-slide-visible.swiper-slide-active {
      //     .testing_content {
      //       transform: translateY(0);
      //     }
      //   }
      //   .swiper-pagination,
      //   .slick-dots {
      //     height: 4.5vw !important;
      //     margin-top: 1em;
      //   }
      //   .swiper-pagination-bullet {
      //     width: 1.75vw;
      //     height: 1.75vw;
      //     margin: 0px 2.380208vw;
      //     &::before {
      //       width: 15.573vw;
      //       height: 15.573vw;
      //       right: -2.5vw;
      //     }
      //   }
      //   .swiper-pagination-bullet-active {
      //     font: normal normal 300 3.74vw Poppins;
      //     margin-left: 19vw !important;
      //   }
      // }
      padding-bottom: 10.394444vw;
      .swiper_thumnail {
        // padding: 5.333vw 0 10.394444vw 4vw;
        padding: 5.333vw 0 7.996vw 4vw;
        width: 100%;
        // .swiper-slide {
        //   max-width: 24.255vw;
        // }
        // .thumnail_img {
        //   width: 24.255vw;
        // }
        .thumnail_img {
          min-height: auto;
        }
      }
      .swiper_image {
        .main_img {
          min-height: 35.468vh;
        }
      }
      .arrow_wrapper {
        position: relative;
        left: 0;
        right: 0;
        justify-content: center;
        gap: 9.25vw;
        // margin-top: 7.996vw;
        .left_arrow,
        .right_arrow {
          max-width: 10.666vw;
          height: 10.666vw;
        }
      }
    }
  }

  .manufacturing_sec4 {
    padding-bottom: 11.7277vw;
    padding-left: 15px;
    .table_equipment {
      font: normal normal normal 6.399vw Secular One;
      max-width: 70vw;
      // padding: 11.7277vw 0 11.7277vw;
      padding-bottom: 9.062vw;
    }
    .testing_table {
      display: block;
      margin: 0;
    }
    .table_content {
      width: 48em;
      margin-bottom: 2.25em;
      .table_heading,
      .table_heading1 {
        font-size: 4.8vw;
        font-weight: 500;
      }
      .table_heading {
        width: 20.5em;
      }
      .table_heading1 {
        width: 27.5em;
      }
      .table_heading,
      .table_heading1 {
        padding: 2.1322vw 0 2.1322vw 9.3284vw;
      }
      .table_desc {
        font: normal normal 300 3.74vw Poppins;
        padding: 2.1322vw 0 2.1322vw 9.3284vw;
      }
      .content:nth-child(1) .table_desc,
      .content:nth-child(2) .table_desc {
        padding: 6.69vw 0 2.1322vw 9.3284vw;
      }
      .content:nth-last-child(1) .table_desc {
        padding: 2.1322vw 0 5.5971vw 9.3284vw;
      }
    }
  }
}

@media (max-width: 600px) {
  .manufacturing_sec1 .common_bg .common_text .heading {
    max-width: 60.361889vw;
    line-height: 2rem;
  }
  .manufacturing_sec1 .common_bg .common_text {
    position: relative;
    top: 33.88358vw;
}
}
