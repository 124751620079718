:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

.application_bg::before {
  opacity: 0.3;
}
.app_breadcrumbs {
  top: 10.916529vw;
  padding-left: 4.6447007138934655vw;
}
.application_sec1 {
  .gallery_bg {
    height: 100vh;
    max-height: 100vh;
  }
  .applications_heading {
    position: relative;
    top: 15.88358045030203vw;
    padding-left: 4.6447007138934655vw;
    h1 {
      max-width: 35.361889071938496vw;
      font: normal normal normal 5.305744096650192vw Secular One;
      background: var(--bg-white-color);
      opacity: 1;
      border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-transform: uppercase;
      line-height: 4.6803953871499178vw;
      padding-bottom: 0.7589236683141132vw;
      margin-bottom: 0;
    }
  }
  .application_img_wrapper {
    .application_banner_copper_img {
      width: 100%;
      max-width: 60vw;
      max-height: 80vh;
      object-fit: contain;
      object-position: right;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
// .Application_card_external_container{
//   width: 100%;
//   height: 100%;
//   background-color: black;
//   background-position: center;
// }
// .parent:hover .Application_card_external_container,
// .parent:focus .Application_card_external_container {
//   transform: scale(1.2);
// }
.application-content-section {
  background: var(--bg-white-color);
  padding: 120px 80px;
}
.application-content-section h3 {
  padding-bottom: 4px;
  font: normal normal normal 2.865vw Secular One;
  letter-spacing: 0px;
  color: var(--primary-color);
  text-transform: uppercase;
  opacity: 1;
}
.application-content-section p {
  width: 90%;
  text-align: left;
  font: normal normal 300 1.042vw Poppins;
  letter-spacing: 0px;
  color: var(--text-color);
  opacity: 1;
}
.Application_card_container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5rem;
  margin-top: 100px;
}
.Application-card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  &::after {
    content: ""; // :before and :after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform: translateY(100%);
    opacity: 0.8;
    transition: all 0.35s ease;
  }
}

.Application_card_external_container {
  position: relative;
  overflow: hidden;
  .image_holder {
    background-size: cover;
    background-position: center;
  }
}

.Application-card-content {
  width: 102%;
  height: 26em;
  padding: 1.5em;
  margin: -2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  color: white;
  transform: translateY(0);
  transition: all 0.35s ease;
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 0.5em;
    height: 8px;
    background-color: #aaa; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: #fff;
  }
}
.Application-card-content ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-gap: 2rem;
}
.Application-card-content ul li {
  text-align: left;
  font: normal normal 300 1.042vw Poppins;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  padding: 10px;
  list-style: decimal;
}
.Application-card-content ul .single_list {
  height: 30em;
}

.Application-card-content ul li a {
  color: rgba(255, 255, 255, 1);
  &:hover {
    text-decoration: underline;
    color: #ffc295;
  }
}
// .Application-card:hover {
//   &::after {
//     z-index: 0;
//     transform: translateY(0);
//   }
//   .Application-card-content {
//     transform: translateY(0);
//     z-index: 1;
//     opacity: 0.8;
//   }
// }

.Application-card-heading h2 {
  color: var(--text-color);
  // font-size: 20px;
  font: normal normal 300 1.042vw Poppins;
  font-weight: 300;
  display: inline-block;
  position: relative;
  margin-top: 15px;
}
.appp:hover .Application-card-heading h2 {
  background-color: unset !important;
  color: var(--primary-color);
  text-decoration: underline;
}

.Application-card-heading h2:hover::after {
  transform: scaleX(1);
  transform-origin: left bottom right;
}
@media (min-width: 1920px) {
  .Application-card-content {
    height: 40em;
  }
  .Application-card-content p {
    text-align: left;
    font: normal normal 300 30px/50px Poppins;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    opacity: 1;
  }
  .application-content-section {
    background: var(--bg-white-color);
    padding: 145px 80px;
  }
}
@media (max-width: 1460px) {
  .application-content-section {
    padding: 110px 95px;
  }
  .Application-card-content {
    height: 25em;
  }
  .application-content-section p {
    width: 90%;
  }
}
@media (max-width: 1280px) {
  .Application-card-content {
    height: 23em;
  }
  .application-content-section p {
    width: 90%;
  }
  .Application-card-content p {
    font: normal normal 300 15px/30px Poppins;
  }
}
@media (max-width: 1024px) {
  .application-content-section {
    padding: 60px 60px;
  }
  .Application_card_container {
    margin-top: 50px;
  }
  .Application-card-content {
    width: 100%;
    height: 25em;
  }
  .application-content-section h3 {
    font-size: 45px;
  }
  .application-content-section p {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .application_sec1 {
    padding-top: 8vh;
  }
}
@media (max-width: 840px) {
  .application_sec1 .gallery_bg {
    height: 43vh;
    max-height: 100vh;
  }
  .application_sec1 .applications_heading {
    top: 25.88358vw;
  }
  .application-content-section p {
    width: 100%;
    font: normal normal 300 3.74vw Poppins;
  }
  .application-content-section {
    padding: 90px 55px;
  }
  .application-content-section h3 {
    font: normal normal normal 6.399vw Secular One;
    line-height: 3rem;
  }
  .Application-card-content {
    height: 21em;
  }
  .app_breadcrumbs {
    top: 13.916529vw;
    padding-left: 5vw;
  }
  .Application-card-heading h2 {
    font: normal normal 300 1.442vw Poppins;
  }
  .Application-card-content ul li {
    font: normal normal 300 1.442vw Poppins;
  }
}
@media (max-width: 825px) {
  .application_bg .MuiBreadcrumbs-root li {
    margin-left: 0.3491488193vw;
    margin-right: 0.3491488193vw;
    color: white;
    opacity: 1;
    text-decoration: none;
    line-height: 1.2081274025vw;
    font: normal normal 300 2.098298vw/1 Poppins;
  }
}
@media (max-width: 768px) {
  .Applicationcall-btn {
    right: 35px;
    bottom: 525px;
  }
  .application-content-section {
    padding: 80px 45px;
  }
  .application_bg .gallery_breadcrumbs {
    top: 6.133333vw !important;
  }
}
@media (max-width: 540px) {
  .application_bg .gallery_breadcrumbs {
    top: 9.133333vw !important;
  }
  .application_sec1 .gallery_bg {
    height: 29vh;
    max-height: 100vh;
  }
  .application_row {
    row-gap: 1rem !important;
  }
  .app_breadcrumbs {
    // top: 22.916529vw;
    padding-left: 5vw;
  }
  .application-content-section {
    padding: 40px 20px;
  }
  .application-content-section h3 {
    font-size: 35px;
    line-height: 2rem;
  }
  .application-content-section p {
    width: 100%;
  }
  .Application-card-heading h2 {
    font: normal normal 300 4.442vw Poppins;
  }
  .Application-card-content ul li {
    font: normal normal 300 3.442vw Poppins;
  }
}
@media (max-width: 467px) {
  .Applicationcall-btn {
    right: 60px;
    bottom: 425px;
  }
  .application-content-section h3 {
    font-size: 20px;
  }
  .application-content-section {
    padding: 70px 20px;
  }
  .Application_card_container {
    margin-top: 50px;
  }
  .application-content-section p {
    width: 100%;
  }
}
