:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

.thankyou_bg {
  height: 100vh;

  .scroll_bg {
    .thankyou_content {
      display: flex;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading {
        font: normal normal normal 5.3057440967vw Secular One;
        background: var(--primary-color);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-transform: uppercase;
        line-height: 4.6803953871vw;
      }

      p {
        text-align: center;
        font: normal normal 300 1.6vw Poppins;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
      }

      Link,
      button {
        background: var(--primary-color);
        opacity: 1;
        font: normal normal 200 1vw Poppins;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        border-radius: 50px;
        padding: 18px 32px;
        border: none;
        outline: none;

        img {
          margin-left: 23px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .thankyou_bg .scroll_bg .thankyou_content .heading {
    font: normal normal normal 12.305744vw Secular One;
    background: transparent linear-gradient(180deg, #ffe4cd 0%, #f2ac6e 100%) 0%
      0% no-repeat padding-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-transform: uppercase;
    line-height: 12.680395vw;
  }
  .thankyou_bg .scroll_bg .thankyou_content p {
    text-align: center;
    font: normal normal 300 4vw Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 0.5em;
  }
  .thankyou_bg .scroll_bg .thankyou_content Link,
  .thankyou_bg .scroll_bg .thankyou_content button {
    background: transparent linear-gradient(180deg, #f5b071 0%, #673200 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    font: normal normal 200 2vw Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-radius: 50px;
    padding: 18px 32px;
    border: none;
    outline: none;
  }
}

@media (max-width: 768px) {
  .thankyou_bg {
    .scroll_bg {
      .thankyou_content {
        .heading {
          font-size: 15.041vw;
          line-height: 12vw;
        }
        p {
          font-size: 4vw;
        }

        Link,
        button {
          font: normal normal 200 3vw Poppins;
          padding: 9px 16px;

          img {
            display: inline-block;
            width: 18px;
            height: 15px;
            background-size: cover;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
