:root {
  --primary-color: #D16C3F;
  --bg-white-color: #FFFFFF;
  --text-color: #373737;
}

@import "../../../common.scss";

.packaging_sec1 {
  // padding: 7.035vw 9.535vw 8.438vw 5.68vw;
  padding: 7.035vw 9.535vw 6.544vw 6.3022vw;
  background: var(--bg-white-color);
  height: 100%;
  .packaging_row {
    .heading {
      // font: normal normal normal 3.0203185063152116vw/3.844041735310269vw Secular One;
      font: normal normal normal 2.865vw Secular One;
      background: var(--primary-color);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      text-align: left;
      border-bottom: 0.1647446458vw solid var(--primary-color);
      margin-bottom: 1.4933vw;
      max-width: 32vw;
    }
    .text {
      // font: normal normal 300 1.098297638660077vw/1.6474464579901154vw Poppins;
      font: normal normal 300 1.042vw Poppins;
      margin: 0;
      color: var(--text-color)
    }
    .img_wrapper {
      position: relative;
      //   padding-left: 4.7vw;
      padding-left: 6.4066vw;
      //   overflow: hidden;
      .packaging_img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
      .pack_img {
        width: 100%;
        max-width: 33.99vw;
        position: absolute;
        left: 2.5vw;
        bottom: -9vw;
      }
    }
  }
}
.packaging_col h4 {
  color: var(--primary-color);
  font-weight: 600;
  font-family: "Poppins";
  margin-top: 10px;
}
.packaging_col p {
  font-family: "Poppins";
  font-weight: 300;
  color: var(--text-color);
}

.packaging_sec2 {
  .product_wrapper {
    background: var(--bg-white-color);
    .main_slider_wrap {
      position: relative;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: center;
    }
    .swiper_image {
      .main_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: cover;
        background: var(--bg-white-color);
      }
    }
    .thumnail_container {
      position: relative;
    }
    .swiper_thumnail {
      background-color: var(--bg-white-color);
      // padding: 2.7083333vw 4.7917vw 4.011vw;
      // padding: 2.7083333vw 10.8854vw 4.011vw;
      padding: 2.7083333vw 0 4.011vw;
      width: 78.25%;
      .swiper-slide {
        width: 25%;
        // height: 100%;
        // max-width: 11.355vw;
        // margin: 0 1vw 0 1vw;
        opacity: 0.4;
      }
      .swiper-slide-thumb-active {
        opacity: 1;
        visibility: visible;
      }
      .thumnail_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .arrow_wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 4.77vw;
      right: 4.77vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 3.4377vw;
        height: 3.4377vw;
        object-fit: contain;
        display: block;
        cursor: pointer;
        z-index: 1;
      }
      // .left_arrow {
      //   position: absolute;
      //   bottom: -25%;
      // }
      // .right_arrow {
      //   position: absolute;
      //   right: 0;
      //   bottom: -25%;
      // }
    }
  }
}

@media (min-width: 2560px) {
  .packaging_sec1 {
    padding: 7.035vw 9.535vw 14.544vw 6.3022vw;
    background: var(--bg-white-color);
    height: 100%;
  }
}
@media (max-width: 820px) {
  .packaging_sec1 .packaging_row .text {
    font: normal normal 300 1.942vw Poppins;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .banner_area {
    padding-top: 8vh;
  }
  .packaging_sec {
    .common_bg {
      .common_text {
        top: 32vw;
      }
    }
  }
  .packaging_sec1 {
    padding: 16.795vw 4vw 24vw 4vw;
    // background-image: url("../../assets/image/packaging/furnace.png");
    // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.75);
    // background-size: cover;
    // background-repeat: no-repeat;
    .packaging_row {
      margin: 0 auto;
      .packaging_col {
        padding: 0;
      }

      .heading {
        font: normal normal normal 6.399vw Secular One;
        max-width: 72vw;
        margin-bottom: 5.866vw;
      }
      .text {
        font: normal normal 300 3.74vw Poppins;
        margin-bottom: 8.2623vw;
      }
      .img_wrapper {
        padding-left: 0;
      }
      .img_wrapper {
        .pack_img {
          position: relative;
          left: initial;
          bottom: initial;
          max-width: 91.955vw;
        }
      }
    }
  }

  .packaging_sec2 {
    .product_wrapper {
      padding-bottom: 10.394444vw;
      .swiper_thumnail {
        // padding: 5.333vw 0 10.394444vw 4vw;
        padding: 5.333vw 0 7.996vw 4vw;
        width: 100%;
        // .swiper-slide {
        //   max-width: 24.255vw;
        // }
        // .thumnail_img {
        //   width: 24.255vw;
        // }
        .thumnail_img {
          min-height: auto;
        }
      }
      .swiper_image {
        .main_img {
          min-height: 35.468vh;
        }
      }
      .arrow_wrapper {
        position: relative;
        left: initial;
        right: initial;
        top: initial;
        bottom: initial;
        justify-content: center;
        // gap: 9.25vw;
        gap: 0;
        // margin-top: 7.996vw;
        .left_arrow,
        .right_arrow {
          max-width: 10.666vw;
          height: 10.666vw;
          margin: 0 15px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .packaging_sec .common_bg .common_text .heading {
    line-height: 2rem;
  }
  .packaging_sec .common_bg .about_breadcrumbs {
    top: 12.133333vw;
  }
  .packaging_sec .common_bg {
    height: 61.96vw;
    .common_text{
      top: 22vw;
    }
  }
}
