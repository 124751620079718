.preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: #141414;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden !important;

  .texts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 2.5em;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: #fff;
  }
}
@media (max-width: 600px) {
  .preloader {
    .texts-container {
      font-size: 1em;
    }
  }
}
