:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

.customerApproval-hero {
  background-image: url(../../assets/Quality/customerapp.jpg);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: top 25% right 0;
  display: flex;
  justify-content: center;
}
.customer-heading {
  position: relative;
  top: 26.88358045030203vw;
}
.customer-heading h2 {
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
  font: normal normal normal 5.305744096650192vw Secular One;
  background: var(--bg-white-color);
  border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  line-height: 4.6803953871499178vw;
  padding-bottom: 0.7589236683141132vw;
  margin-bottom: 0;
}
.approval_row {
  height: auto;
  // min-height: 126.375em;
}
.application_box {
  border-right: 1px solid #707070;
  cursor: pointer;
  margin: 0 0 6.5rem;
  padding: 1.875rem 3.875em;
}
.CustomerApproval_sec2 h2::after {
  content: "";
  display: block;
  width: 9em;
  max-width: 100%;
  border-bottom: 2px solid #ffc295;
  margin: 0.3em 0;
}

// .yarl__fullsize {
//   background: linear-gradient(rgba(30, 30, 30, 0.9), #000 1810%) !important;
// }

// .yarl__portal_open:focus-visible {
//   outline: none
//   // outline: -webkit-focus-ring-color auto 1px;
// }

.yarl__slide .yarl__slide_image {
  // padding: 2.388em 0;
  padding: 2.388em 0 5.5em;
}

.yarl__container {
  .yarl__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 3vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    // font-size: 15px;
    font-size: 16px;
    color: #d7d7d7;
    z-index: 0;
    // max-width: 55px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 100%;
    max-width: 51.6px;
    height: 51.6px;
  }

  .yarl__navigation_next,
  .yarl__navigation_prev {
    padding: 15px;
    font-size: 26px;
    border-radius: 50%;
    background: #151414 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    opacity: 1;
  }

  .yarl__navigation_next {
    margin-right: 15vw;
  }

  .yarl__navigation_prev {
    margin-left: 15vw;
  }
}

@media (max-width: 1024px) {
  .approval_row .col-md-4:nth-last-child(3) .application_box {
    padding: 0;
    margin: 0 0 40px;
    padding-left: 0;
  }
}

@media (max-width: 992px) {
  .CustomerApproval_sec1 {
    padding-top: 8vh;
  }
  .approval_row {
    min-height: auto;
  }
}
@media (max-width: 600px) {
  .yarl__container {
    .yarl__navigation_next,
    .yarl__navigation_prev {
      transform: translateY(0);
      top: 81%;
    }
    .yarl__navigation_prev {
      margin-left: 0;
      left: 32%;
    }
    .yarl__navigation_next {
      margin-right: 0;
      right: 32%;
    }
  }
}

@media (max-width: 467px) {
  .customer-heading {
    top: 32vw;
  }
  .customer-heading h2 {
    max-width: fit-content;
  }
}
