@import "../../common.scss";

.common_bg {
  height: 59.362987369577155vw;
  // height: 100vh;
  // max-height: 100vh;
  // min-height: 100vh;
  // max-height: 100vh;
  background-size: cover;
  position: relative;
  width: 100%;
  background-position: center;

  &:before {
    content: "";
    position: absolute;
    opacity: 0.85;
    width: 100%;
    height: 100%;
    // background: #141414 0% 0% no-repeat padding-box;
  }

  .about_breadcrumbs img {
    vertical-align: initial;
  }

  .common_text {
    position: relative;
    top: 26.88358045030203vw;
    .heading {
      margin: 0 auto;
      // max-width: 60.361889071938496vw;
      max-width: fit-content;
      font: normal normal normal 5.305744096650192vw Secular One;
      background: var(--bg-white-color);
      border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      text-transform: uppercase;
      line-height: 4.6803953871499178vw;
      // padding-bottom: 1.614588vw;
      padding-bottom: 1vw;
      margin-bottom: 0;
    }
  }
  .scroll-down-d {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.common_breadcrumb {
  background: #141414 0% 0% no-repeat padding-box;
  color: #fff;
  // padding-top: 10.9375vw;
  .about_breadcrumbs img {
    vertical-align: initial;
  }
  .about_breadcrumbs {
    // top: 10.9375vw;
    padding-left: 6.3022vw;
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .common_bg {
    .about_breadcrumbs {
      top: 14.916529vw;
    }
  }
}

@media (max-width: 767px) {
  .common_bg {
    // height: 84.474464579901152vw;
    height: 79.96vw;
    &::before {
      opacity: 0;
    }
    .about_breadcrumbs {
      top: 28.133333333333333vw;
    }
    .common_text {
      .heading {
        font: normal normal normal 7.305744096650192vw Secular One;
        max-width: 47.361889vw;
      }
    }
    .scroll-down-d {
      display: none;
    }
  }

  .common_breadcrumb {
    .about_breadcrumbs {
      padding-left: 4vw;
    }
  }
  //   .common_sec_mob {
  //     .common_section.mobile {
  //       .common_bg {
  //         width: 100vw;
  //         height: 80vw;
  //       }
  //     }
  //   }
}

// @media (max-width: 600px) {
//   .common_bg .about_breadcrumbs {
//     top: 12.133333vw;
//   }
//   .common_bg .common_text {
//     top: 22vw !important;
//   }
//   .common_bg {
//     height: 61.96vw;
//   }
// }
