:root {
  --primary-color: #D16C3F;
  --text-color: #373737;
}

.footer {
  position: relative;

  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
  height: 100%;
}

.footer_set {
  padding-left: 6.302083333333333vw;
  padding-right: 6.302083333333333vw;
  padding-top: 4.427083333333333vw;
}
.hideFooter {
  display: none;
}
.footer_set .footer_div_main {
  padding-bottom: 7.910349373764008vw;
}

.footer::before {
  content: "";
  position: absolute;
  opacity: 0.95;
  width: 100%;
  height: 100%;
  background: #E8E8E8;
}

.footer span {
  color: #ffffff;
}

.footer .logo {
  margin-bottom: 2.504118616144975vw;
}

.footer .logo img {
  // width: 11.092806150466776vw;
  height: 4.8325096101043385vw;
}

.footer_set .quick-links {
  /* padding-left:  6.86161449752883vw; */
}

.footer_set .about_us-links {
  /* padding-left:  2.86161449752883vw; */
}

.footer .about-d p {
  color: var(--text-color) !important;
  max-width: 16.474464579901152vw;
  font: normal normal 300 0.8333333333333334vw/1.5625vw Poppins;
}

.footer .Address_link .addres-d p {
  color: #ffffff;
  /* max-width: 16.474464579901152vw; */
  margin-top: 1vw;
  font: normal normal 300 0.8333333333333334vw/1.5625vw Poppins;
}

.footer .Address_link .addres-d p {
  margin-bottom: 1.546875vw;
}

.footer .Address_link span,
.footer .about_us-links span,
.footer .product-links span,
.footer .quick-links span {
  color: var(--primary-color);
  font: normal normal 0.9375vw/1.1458333333333333vw Poppins;
}

.footer .about_us-links ul,
.footer .product-links ul,
.footer .quick-links ul {
  padding-left: 0px;
  margin-top: 1vw;
  margin-bottom: 0;
}

.footer .product-links ul li a,
.footer .about_us-links ul li a,
.footer .quick-links ul li a {
  color: var(--text-color);
  font: normal normal 300 0.8333333333333334vw/1.1458333333333333vw Poppins;
  text-decoration: none;
}

.footer .product-links ul li,
.footer .quick-links ul li,
.footer .about_us-links ul li {
  padding-bottom: 0.5208333333333334vw;
}

.footer .product-links ul li:last-child {
  padding-bottom: 0;
}

.footer .quick-links ul li:last-child {
  padding-bottom: 0;
}

.footer .about_us-links ul li:last-child {
  padding-bottom: 0;
}

.footer .about_us-links,
.footer .quick-links,
.footer .product-links {
  /* line-height: 32px; */
}

.Address_link .about-d {
  margin-top: 1.3179571663920921vw;
}

.footer .contact-d a {
  color: #ffffff;
  text-decoration: none;
  font: normal normal 300 0.8333333333333334vw/1.5625vw Poppins;
  margin-bottom: 0.5359375vw;
  letter-spacing: 0.041666666666666664vw;
  gap: 0.4640625vw;
}

.footer .contact-d a span {
  font-size: 18px;
}

.footer .contact-d a > p {
  margin: 0;
}

.footer .contact-d a > svg {
  width: 1.043382756727073vw;
  height: 1.043382756727073vw;
}

.copy-right-d {
  padding-left: 5.9375vw;
  padding-top: 2.2916666666666665vw;
  padding-bottom: 1.9527732015376165vw;
  padding-right: 6.666666666666667vw;
  border-top: 0.06591957811470006vw solid var(--text-color);
}

.copy-right-d p {
  font: normal normal 300 0.8333333333333334vw/2.0833333333333335vw Poppins;
  margin: 0;
  color: var(--text-color);
}
.copy-right-d p a {
  color: var(--text-color) !important;
}
.copy-right-d a:hover {
  border-bottom: 2px solid #fff;
  font-weight: bold;
}
.scrollTop {
  position: fixed;
  /* background-image: linear-gradient(#164f77 , #f2faff ); */
  background: var(--primary-color);
  border: none;
  /* padding: 6px 12px; */
  height: 3.4047226798462384vw;
  width: 3.4047226798462384vw;
  border-radius: 50%;
  color: white;
  /* font-size: 20px; */
  right: 3.0752333882482152vw;
  text-align: "center";
  transition: background-image 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  transform: translate(100px, 50px);
  transition: 0.5s;
  /* z-index: 1000; */
  /* cursor: pointer; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.scrollTop:active,
.scrollTop:hover {
  background-color: var(--primary-color);
}

.show_btn {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 10px);
  transition: 0.5s;
  top: 44.5vw;
  z-index: 999;
}

.QUICK_QUOTE {
  /* rotate: 270deg; */
}

.show_btn img {
  width: 1.6474464579901154vw;
  height: 1.672872048325096vw;
}

.Scroller_button {
  text-align: right;
  position: absolute;
  top: 1em;
  right: 5vw;
  z-index: 999;
}

.Scroller_button img {
  width: 60px;
  height: 60px;
}

@media (max-width: 1023px) {
  /* .accordion {
    background: transparent !important;
  }
  .footer .accordion-header {
    margin-bottom: 0.4em;
    margin-top: 1em;
    position: relative;
    width: 100%;
  }
  .footer .accordion-header .accordion-button {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    font-family: Poppins, Medium;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.48px;
    text-align: left;
    width: 90%;
    width: 100%;
  } */
  .projects {
    .accordion{
      .accordion-item {
        background-color: transparent;
        border: none;
      }
    }
    .accordion-header {
      position: relative;
      width: 100%;
      // background-color: #141414;
      .accordion-button .icon {
        position: absolute;
        right: 0;
        bottom: 40%;
        width: 100%;
        max-width: 17px;
        // transform: rotate(180deg);
        color: #f5b071;
        transform: rotate(0deg);
      }
      .accordion-button.collapsed {
        .icon {
          transform: rotate(180deg);
        }
      }
      .accordion-button {
        width: 90%;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: Poppins, Medium;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        letter-spacing: 0.48px;
        background: transparent;
        border: none;
        padding-left: 0;
        box-shadow: none;
        &::after {
          display: none;
        }

        p {
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .accordion-body {
      width: 100%;
      text-align: left;
      color: #141414;
      padding-top: 0;
      padding-left: 0;
      font-family: 'Poppins';
      font-weight: lighter;
      font-size: 14px;
      letter-spacing: 0.4px;
      ul {
        padding: 0;
        li {
          padding-bottom: 15px;          
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .footer .about-d p {
    max-width: 50vw;
    font-size: 14px;
    line-height: 1.5rem;
  }
  .footer .Address_link span,
  .footer .about_us-links span,
  .footer .product-links span,
  .footer .quick-links span {
    font-size: 18px;
  }
  .footer .product-links ul li a,
  .footer .about_us-links ul li a,
  .footer .quick-links ul li a {
    font-size: 15px;
  }
  .footer .contact-d a {
    font-size: 14px;
    line-height: 2.5rem;
  }
  .footer .Address_link .addres-d p {
    font-size: 14px;
    line-height: 1.5rem;
  }
  .Scroller_button {
    position: absolute;
    top: 5%;
    right: 10%;
  }
}

@media only screen and (max-width: 676px) {
  .footer {
    height: auto;
  }

  .footer .about-d {
    font: normal normal 300 3.7333333333333334vw/5.866666666666666vw Poppins;
    max-width: 80vw;
  }

  .footer .about-d p {
    color: #ffffff;
    max-width: 100vw;
    font-size: 12px;
    line-height: 1rem;
    margin-bottom: 0;
  }
  .footer .Address_link .addres-d p {
    margin-bottom: 1.546875vw;
    font-size: 12px;
    line-height: 1rem;
  }
  .footer_set .quick-links {
    padding-left: 2.86161449752883vw;
  }

  .footer .about_us-links,
  .footer .quick-links {
    line-height: 0.43931905546403077vw;
  }

  .footer .quick-links ul li a,
  .footer .about_us-links ul li a,
  .footer .product-links ul li a {
    font: normal normal 300 3.7333333333333334vw/5.866666666666666vw Poppins;
  }

  .footer .quick-links span,
  .footer .about_us-links span,
  .footer .product-links span {
    font: normal normal 4.672872048325096vw/5.866666666666666vw Poppins;
  }

  .footer .contact-d a > p {
    font: normal normal 2.672872048325096vw Poppins;
  }

  .footer .quick-links ul,
  .footer .about_us-links ul,
  .footer .product-links ul {
    margin-top: 5.866666666666666vw;
    margin-bottom: 0;
  }

  .footer .contact-d a > svg {
    width: 2.043382756727073vw;
    height: 2.043382756727073vw;
  }

  .contact-d {
    display: none;
  }

  .copy-right-d {
    margin-left: 2.6447007138934655vw;
    margin-right: 2.628775398132894vw;
    padding-left: 0;
    padding-right: 0;
    padding-top: 2.736000000000001vw;
    padding-bottom: 2.736000000000001vw;
  }

  .copy-right-d .d-flex {
    flex-direction: column;
  }

  .copy-right-d p {
    font: normal normal 300 3.7333333333333334vw/5.866666666666666vw Poppins;
    margin-bottom: 2.1333333333333333vw;
  }

  .footer .logo img {
    // width: 38.026666666666664vw;
    height: 16.464vw;
  }

  .footer_set {
    padding-top: 16.666666666666668vw;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .footer .logo {
    margin-bottom: 6.4vw;
  }

  .footer .Address_link span {
    font: normal normal 4.672872048325096vw Poppins;
  }

  .footer .quick-links,
  .footer .about_us-links,
  .footer .product-links,
  .footer .Address_link {
    padding-top: 10.666666666666666vw;
  }

  .scrollTop {
    height: 8.1047226798462384vw;
    width: 8.1047226798462384vw;
    top: auto;
    bottom: 5em;
  }

  .show_btn img {
    width: 4.917957166392092vw;
    height: 4.917957166392092vw;
  }

  .mail_btn {
    height: 8.1047226798462384vw;
    width: 8.1047226798462384vw;
    top: auto;
    bottom: 22.933333333333334vw;
  }

  .mail_btn img {
    width: 3.917957166392092vw;
    height: 2.917957166392092vw;
  }

  .footer .Address_link .addres-d {
    font: normal normal 300 3.7333333333333334vw/5.866666666666666vw Poppins;
    margin-top: 5.866666666666666vw;
  }

  .Scroller_button {
    display: none;
  }
}


