:root {
  --primary-color: #d16c3f;
  --bg-white-color: #ffffff;
  --text-color: #373737;
}

.certification_bg {
  height: 59.362987369577155vw;
  background-size: cover;
  position: relative;
  width: 100%;
  background-position: center;
}
.certification_bg:before {
  content: "";
  position: absolute;
  opacity: 0.85;
  width: 100%;
  height: 100%;
}
.certification_breadcrumbs {
  position: absolute;
  top: 10.916529379461835vw;
  left: 5vw;
}
.certification_breadcrumbs img {
  width: 1.4277869302580999vw;
  height: 1.4277869302580999vw;
}

.certification_breadcrumbs .MuiBreadcrumbs-root li a {
  color: white;
  opacity: 1;
  text-decoration: none;
  text-transform: uppercase;
}

.certification_breadcrumbs .MuiBreadcrumbs-root li {
  margin-left: 0.3491488193300385vw;
  margin-right: 0.3491488193300385vw;
  color: white;
  opacity: 1;
  text-decoration: none;
  line-height: 1.2081274025260846vw;
  font: normal normal 300 1.098297638660077vw Poppins;
}
.certificate_heading {
  position: relative;
  top: 26.88358045030203vw;
}
.certificate_heading h2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 39.361889071938496vw;
  font: normal normal normal 5.305744096650192vw Secular One;
  background: var(--bg-white-color);
  border-bottom: 0.16474464579901152vw solid var(--bg-white-color);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  line-height: 4.6803953871499178vw;
  padding-bottom: 0.7589236683141132vw;
  margin-bottom: 0;
}
.quality-assurance-section {
  padding: 9.875em 4.528em;
  background-color: transparent;
}
.quality-assurance-section h2 {
  text-align: left;
  font: normal normal normal 2.865vw Secular One;
  letter-spacing: 0px;
  color: var(--primary-color);
  text-transform: uppercase;
  opacity: 1;
  padding-bottom: 2vw;
}
.quality-assurance-section h2::after {
  content: "";
  display: block;
  width: 10em;
  max-width: 90%;
  border-bottom: 2px solid var(--primary-color);
  margin: 0.3em 0;
}
.quality-assurance-section p {
  text-align: left;
  font: normal normal 300 1.042vw Poppins;
  letter-spacing: 0px;
  color: var(--text-color);
  opacity: 1;
  padding-bottom: 12px;
}
.application_box {
  border-right: 1px solid #707070;
  cursor: pointer;
  margin: 0 0 6.5rem;
  padding: 1.875rem 2.875em;
}
.application_box img {
  height: 100% !important;
  max-height: 60vh !important;
  border: 1px solid #707070;
}
.certificate_row {
  margin-top: 75px;
}
.certificate_row {
  padding: 0;
  .col-md-4 {
    text-align: center;
    a {
      text-decoration: none;
      border: none;
    }
    &:nth-child(3n) .application_box {
      border: none;
    }
    &:last-child .application_box {
      border: none;
    }

    &:nth-last-child(1) .application_box,
    &:nth-last-child(2) .application_box {
    // &:nth-last-child(3) .application_box {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .application_box {
      border-right: 1px solid #707070;
      cursor: pointer;
      margin: 0 0 6.5rem;
      // padding: 1.875rem 2.875em;
    }
  }
}

.fslightbox-container {
  font-family: Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 0 5em;
  background: linear-gradient(rgba(30, 30, 30, 0.9), #000 1810%) !important;
  z-index: 1000000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
  .fslightboxs {
    padding: 0 0 2.5em;
  }
}
.fslightbox-slide-btn-next-container {
  padding-right: 15vw !important;
}
.fslightbox-slide-btn-previous-container {
  left: 15vw !important;
  border-radius: 50%;
}
.fslightbox-slide-btn {
  padding: 15px;
  font-size: 26px;
  border-radius: 50%;
  background: #151414 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 1;
}
.fslightbox-toolbar-button:first-child {
  display: none !important;
}
// .fslightbox-toolbar {
//   position: absolute;
//   z-index: 3;
//   right: 32vw !important;
//   top: 4.5vw !important;
//   height: 100%;
//   display: flex;
//   background: transparent !important;
// }
.fslightbox-nav {
  height: 45px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
// .fslightboxs {
//   width: 100% !important;
//   height: 35em !important;
// }

.fslightbox-slide-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  bottom: -92vh !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
  font-size: 15px;
  color: #d7d7d7;
  z-index: 0;
  max-width: 55px;
  text-align: left;
  .fslightbox-flex-centered {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    .fslightbox-slash {
      display: none;
    }
    span:last-child {
      display: none;
    }
  }
}
@media (max-width: 2560px) {
  .Application-card-content p {
    font: normal normal 300 30px/50px Poppins;
  }
}
@media (max-width: 1440px) {
  .application_box {
    padding: 1.875rem 2.875em;
  }
  .fslightbox-slide-btn-previous-container {
    left: 15vw !important;
  }
  .fslightbox-slide-btn-next-container {
    padding-right: 15vw !important;
  }
  // .fslightboxs {
  //   width: 100% !important;
  //   height: 40em !important;
  // }
}
@media (max-width: 1024px) {
  // .fslightboxs {
  //   width: 100% !important;
  //   height: 50em !important;
  // }
  .certificate_row .col-md-4 {
    width: 50%;
  }
  .application_box img {
    width: 100%;
    max-width: 15em;
  }
  .certificate_row {
    padding: 0 15px;
    .col-md-4 {
      &:nth-child(1n) .application_box {
        padding-left: 0;
        padding-right: 1em;
      }
      &:nth-child(3) .application_box {
        padding-left: 0;
        padding-right: 1em;
        border-right: 1px solid #707070;
      }
      &:nth-child(2n) .application_box {
        border: none;
        padding-left: 1em;
        padding-right: 0;
      }
      &:nth-child(9) .application_box {
        border-right: 1px solid #707070;
      }
      &:last-child .application_box {
        border: none;
      }
      .application_box {
        padding: 6px 0 0;
        margin: 0 0 40px;
      }
      &:nth-last-child(3) .application_box {
        padding: 6px 0 0;
        margin: 0 0 40px;
        padding-left: 1em;
      }
    }
  }
}
@media (max-width: 840px) {
  .certificate_sec1 {
    padding-top: 8vh;
  }
  .certification_bg:before {
    display: none;
  }
  .certification_breadcrumbs {
    top: 3.916529vw;
  }
  .certification_breadcrumbs .MuiBreadcrumbs-root li {
    font: normal normal 300 2.0982976387vw Poppins;
  }
  .certificate_row {
    margin-top: 63px;
  }
  .quality-assurance-section {
    padding: 60px 50px;
    background-color: transparent;
  }
  .quality-assurance-section h2 {
    font-size: 45px;
    padding-bottom: 14px;
    font: normal normal normal 6.399vw Secular One;
  }
  .quality-assurance-section p {
    width: 100%;
    font: normal normal 300 3.74vw Poppins;
  }
  .application_box {
    padding: 1.875rem 2.875em;
  }
  .application_box img {
    width: 145px;
    height: 205px;
  }
  // .fslightbox-toolbar {
  //   right: 18vw;
  //   top: 25vw;
  // }
  .fslightbox-slide-btn-previous-container {
    left: 5vw !important;
  }
  .fslightbox-slide-btn-next-container {
    padding-right: 5vw !important;
  }
}
@media (max-width: 767px) {
  .certificate_row {
    .col-md-4:nth-last-child(3) .application_box {
      padding-bottom: 1.875rem;
      margin-bottom: 6.5rem;
    }
  }
}
@media (max-width: 755px) {
  .certification_breadcrumbs {
    position: absolute;
    top: 5.916529vw;
    left: 7%;
  }
  .certification_breadcrumbs .MuiBreadcrumbs-root li {
    line-height: 1.2081274025vw;
    font: normal normal 300 2.598298vw Poppins;
  }
  .certification_breadcrumbs img {
    width: 2.427787vw;
    height: 2.427787vw;
  }
  .certificate_heading h2 {
    max-width: 47.361889vw;
    font: normal normal normal 6.305744vw Secular One;
    line-height: 7.680395vw;
  }
  .quality-assurance-section {
    padding: 40px 40px;
  }
  .quality-assurance-section h2 {
    font-size: 20px;
    padding-bottom: 8px;
  }
  // .quality-assurance-section p {
  //   font-size: 12px;
  // }
  .certificate_row {
    padding: 0 15px;
    .col-md-4 {
      &:nth-child(1n) .application_box {
        padding-left: 0;
        padding-right: 1em;
      }
      &:nth-child(3) .application_box {
        padding-left: 0;
        padding-right: 1em;
        border-right: 1px solid #707070;
      }
      &:nth-child(2n) .application_box {
        border: none;
        padding-left: 1em;
        padding-right: 0;
      }
      &:nth-child(9) .application_box {
        border-right: 1px solid #707070;
      }
      &:last-child .application_box {
        border: none;
      }
      .application_box {
        padding: 6px 0 0;
        margin: 0 0 40px;
      }
      &:nth-last-child(3) .application_box {
        padding: 0 0 0;
        margin: 0 0 40px;
        padding-left: 1em;
      }
    }
  }
  .application_box img {
    width: 145px !important;
    height: 186px !important;
  }
  // .fslightboxs {
  //   height: 100% !important;
  //   max-height: 35em !important;
  // }
  .fslightbox-slide-btn-container {
    top: 85% !important;
  }
  .fslightbox-slide-btn-next-container {
    padding-right: 30vw !important;
  }
  .fslightbox-slide-btn-previous-container {
    left: 30vw !important;
  }
  // .fslightbox-toolbar {
  //   right: 5vw;
  //   top: 42vw;
  // }
}
@media (max-width: 467px) {
  .certificate_heading {
    top: 32vw;
  }
  .certification_breadcrumbs {
    position: absolute;
    top: 4vh;
    left: 3%;
  }
  .quality-assurance-section {
    padding: 40px 20px;
  }
  .quality-assurance-section h2 {
    font-size: 20px;
  }
  // .quality-assurance-section p {
  //   font-size: 12px;
  //   padding-bottom: 2px;
  // }
  .certificate_row {
    .col-md-4 {
      text-align: start;
      .application_box {
        padding: 0 0 0;
      }
    }
  }
}
@media (max-width: 320px) {
  .application_box img {
    width: 115px !important;
    height: 150px !important;
  }
}
