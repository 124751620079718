:root {
   --primary-color: #D16C3F;
   --bg-white-color: #FFFFFF;
   --text-color: #373737;
}

.privacy_section{
   height: 100vh;

   .privacy_sec_bg {
      .notfound_content {
         display: flex;
         height: 100vh;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .heading {
            font: normal normal normal 8vw Secular One;
            background: var(--primary-color);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            text-transform: uppercase;
            line-height: 6.6803953871vw;
         }

         p {
            text-align: center;
            font: normal normal 300 1.6vw Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-transform: uppercase;
            opacity: 1;
         }

         Link,
         button {
            background: var(--primary-color);
            opacity: 1;
            font: normal normal 200 1vw Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            border-radius: 50px;
            padding: 18px 32px;
            border: none;
            outline: none;

            img {
               margin-left: 23px;

            }

         }

      }
   }


}

@media (max-width:768px) {
   .privacy_section { 
      height: 100vh;     
      .privacy_sec_bg{
         height: 100vh;
         background-size: cover;
         .notfound_content{
            .heading {
               font-size: 25.041vw;
               line-height:30vw;
            }
            p{
               font-size: 5vw;
            }

            Link,
            button {
               font: normal normal 200 3vw Poppins;
               padding: 9px 16px;

               img {
                  display: inline-block;
                  width: 18px;
                  height: 15px;
                  background-size: cover;
                  margin-left: 10px;
               }
            }
         }


      }
   }

}

